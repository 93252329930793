import { Box } from '@/components/atoms/Box';
import { Dialog } from '@/components/molecules/Dialog';
import { FadeOut } from '@/components/molecules/FadeOut/FadeOut';
import { Popper } from '@/components/molecules/Popper';
import { StyledFlexGap20, StyledFlexNowrap } from '@/components/styled';
import { StyledDialogResetPasswordrCloseButton } from '@/components/styled/button';
import { useAuthContext } from '@/contexts/AuthContext';
import { TaskTrayIconWithAnimation } from '@/features/components/atoms/TaskTrayIconWithAnimation';
import { useTaskTray } from '@/hooks/global/useTaskTray';
import { useDialog } from '@/hooks/local/useDialog';
import { PopperProps } from '@mui/material';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  StyledUserIcon,
  StyledUserInfoBox,
  StyledUserInfoPartition,
} from '../styled';
import { TaskTrayPopper } from './TaskTrayPopper';
import { UserInfoPopper } from './UserInfoPopper';
import { useTaskTrayPopper } from './hooks/useTaskTrayPopper';

type PasswordResetDialogProps = {
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
}

const PasswordResetDialog = ({
  isOpenDialog,
  handleCloseDialog,
}: PasswordResetDialogProps) => {
  return (
    <Dialog
      isOpenDialog={isOpenDialog}
      onClose={handleCloseDialog}
      maxWidth="sm"
      hasCloseIcon={false}
      dialogTitle="パスワード再設定用のメールを送信しました"
    >
      ご登録済みのメールアドレス宛に、パスワード再設定用のURLを記載したメールを送信しました。<br />
      記載のURLから、再設定を行ってください。< br />
      <br />
      ※URLの有効期間は5日間（120時間）です
      <Box style={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '24px 0',
      }}>
        <StyledDialogResetPasswordrCloseButton
          onClick={handleCloseDialog}
        >
          閉じる
        </StyledDialogResetPasswordrCloseButton>
      </Box>
    </Dialog>
  )
}

/**
 * JSDoc
 * @see ヘッダーのポッパー群
 * @see ポッパーのコンテナを統一しないと複数のポッパーが開けてしまう
 * @see 下層のユーザー情報ポッパーとタスクトレイポッパーをエレメントとして格納している
 * @returns {JSX.Element}
 */
export const Poppers = memo((): JSX.Element => {
  const {
    isOpenTaskTrayPopper,
    handleOpenTaskTrayPopper,
    handleCloseTaskTrayPopper,
    anchorElement,
  } = useTaskTrayPopper();
  const { isTaskTrayAnimation, setIsOpenTaskTray, setIsTaskTrayAnimation } =
    useTaskTray();

  const [popperType, setPopperType] = useState<string>('');
  const [popperTitle, setPopperTitle] = useState<string>('');
  const [popperElement, setPopperElement] = useState<JSX.Element | null>(null);
  const [popperPlacement, setPopperPlacement] = useState<
    'bottom-end' | 'bottom'
  >('bottom-end');
  const { user } = useAuthContext();
  const taskTrayIconRef = useRef<HTMLDivElement | null>(null);
  const [isHoverPopper, setIsHoverPopper] = useState<boolean>(false);
  const [isFadeOut, setIsFadeOut] = useState<boolean>(false);
  const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleCloseHeaderPopper = useCallback(() => {
    if (popperType === 'taskTray') {
      setIsOpenTaskTray(false);
      setIsTaskTrayAnimation(false);
      setIsHoverPopper(false);
    }
    handleCloseTaskTrayPopper();
  }, [
    handleCloseTaskTrayPopper,
    popperType,
    setIsOpenTaskTray,
    setIsTaskTrayAnimation,
  ]);

  const handleOpenPopperElement = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => {
      setIsFadeOut(false);
      setPopperType(type);
      if (type === 'userInfo') {
        setPopperTitle('');
        setPopperElement(
          <UserInfoPopper
            handleOpenPasswordResetDialog={handleOpenDialog}
            handleCloseThisPopper={handleCloseHeaderPopper}
          />
        );
        setPopperPlacement('bottom');
      }
      if (type === 'taskTray') {
        /* apiから生成中のみ表示 */
        setPopperTitle(`生成キュー`);
        setPopperElement(<TaskTrayPopper />);
        setPopperPlacement('bottom');
        setIsOpenTaskTray(true);
        setIsTaskTrayAnimation(true);
      }
      handleOpenTaskTrayPopper(e);
    },
    [
      handleOpenTaskTrayPopper,
      handleOpenDialog,
      handleCloseHeaderPopper,
      setIsOpenTaskTray,
      setIsTaskTrayAnimation,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleHoverPopper = useCallback(() => {
    console.log('handleHoverPopper');
    if (isHoverPopper) return;
    setIsHoverPopper(true);
  }, [isHoverPopper, setIsHoverPopper]);
  /*
  const handleAwayPopper = useCallback(() => {
    console.log('handleAwayPopper');
    if (!isHoverPopper) return;
    setIsHoverPopper(false);
  }, [isHoverPopper, setIsHoverPopper]);
*/
  useEffect(() => {
    if (!isTaskTrayAnimation || !taskTrayIconRef.current) return;
    taskTrayIconRef.current.click();
  }, [isTaskTrayAnimation]);

  useEffect(() => {
    // biome-ignore lint/suspicious/noEmptyBlockStatements:
    if (isTaskTrayAnimation || isHoverPopper) return () => {};
    const timer = setTimeout(() => {
      setIsFadeOut(true);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isHoverPopper, isTaskTrayAnimation]);

  return (
    <>
      <StyledFlexGap20>
        <Box
          ref={taskTrayIconRef}
          onClick={(e) => {
            handleOpenPopperElement(e, 'taskTray');
          }}
        >
          <TaskTrayIconWithAnimation />
        </Box>
        <StyledUserInfoBox
          onClick={(e) => {
            handleOpenPopperElement(e, 'userInfo');
          }}
        >
          <StyledUserIcon />
          <StyledFlexNowrap>
            {user && user.email && <>{user.email}</>}
            <StyledUserInfoPartition>|</StyledUserInfoPartition>
            {user && user.organization && <>{user.organization?.displayName}</>}
          </StyledFlexNowrap>{' '}
        </StyledUserInfoBox>
      </StyledFlexGap20>
      <FadeOut
        isFadeOut={isFadeOut}
        onAnimationComplete={handleCloseHeaderPopper}
      >
        {(popperElement && isOpenTaskTrayPopper) && (
          <Popper
            title={popperTitle}
            titleWeight="bold"
            onClose={handleCloseHeaderPopper}
            anchorElement={anchorElement as unknown as HTMLElement}
            placement={popperPlacement as PopperProps['placement']}
            placementY={25}
            closeIconSize={24}
            onMouseOver={handleHoverPopper}
          >
            {popperElement}
          </Popper>
        )}
      </FadeOut>
      <PasswordResetDialog
        isOpenDialog={isOpenDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </>
  );
});
