import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledConfirmDialogContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledConfirmDialogContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledConfirmBody = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledConfirmBody} {...props}>
      {children}
    </Box>
  );
});

export const StyledTitle = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledTitle} {...props}>
      {children}
    </Box>
  );
});

export const StyledProgressText = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledProgressText} {...props}>
      {children}
    </Box>
  );
});

export const StyledTipsText = memo(({ children, ...props }: React.ComponentProps<'div'>) => {
  return (
    <div className={vanilla.styledTipsText} {...props}>
      {children}
    </div>
  );
});
