import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const initialValues = {
  isOpenConfirmBackgroundDialog: false,
  isSubmittedConfirmBackgroundDialog: false,
};
const isOpenConfirmBackgroundDialog = atom<boolean>(initialValues.isOpenConfirmBackgroundDialog);
const isSubmittedConfirmBackgroundDialog = atom<boolean>(initialValues.isSubmittedConfirmBackgroundDialog);

export const useConfirmBackgroundDialog = () => {
  const [isOpenDialog, setIsOpenDialog] = useAtom(isOpenConfirmBackgroundDialog);
  const [isSubmittedDialog, setIsSubmittedDialog] = useAtom(isSubmittedConfirmBackgroundDialog);

  const handleOpenDialog = useCallback(() => {
    setIsOpenDialog(true);
  }, [setIsOpenDialog]);

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [setIsOpenDialog]);

  const handleSubmit = useCallback(() => {
    setIsOpenDialog(false);
    setIsSubmittedDialog(true);
  }, [
    setIsOpenDialog,
    setIsSubmittedDialog,
  ]);

  const handleReset = useCallback(() => {
    setIsOpenDialog(initialValues.isOpenConfirmBackgroundDialog);
    setIsSubmittedDialog(initialValues.isSubmittedConfirmBackgroundDialog);
  }, [
    setIsOpenDialog,
    setIsSubmittedDialog,
  ])

  return {
    isOpenDialog,
    isSubmittedDialog,
    // setIsOpenDialog,
    // setIsSubmittedDialog,
    handleOpenDialog,
    handleCloseDialog,
    handleSubmit,
    handleReset,
  };
};
