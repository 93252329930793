import houndstooth from '@/assets/any/houndstooth.png';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { ProgressDialog } from '@/features/components/modals/ProgressDialog';
import { useGenerate } from '@/features/components/steps/Setting/hooks/useGenerate';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesStepLayout } from '@/features/components/templates/Layout/FeaturesStepLayout';
import { memo, useMemo, useState } from 'react';
import { GlobalViewerMode } from '../../organisms/GlobalViewer';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { Preview } from './Preview';
import { useHandlers, useProgress, useRequest, useSkip } from './hooks';

export const Setting = memo((): JSX.Element => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  const {
    apiPostResponse,
    apiGetResponse,
    handlePostPreviewApi,
    handleGetPreviewApi,
    setPostApiResponse,
    setGetApiResponse,
  } = useGenerate();

  const {
    isOpenProgress,
    loadingProgressTitle,
    loadingProgressPercent,
    handleCloseProgress,
  } = useProgress({
    apiPostResponse,
    apiGetResponse,
  });

  const {
    handleClear,
    handleResetAfterPreview,
    handleGenerate,
    handleStopGenerating,
  } = useHandlers({
    handlePostPreviewApi,
    setPostApiResponse,
    setGetApiResponse,
    handleCloseProgress,
  });

  useSkip({
    handleResetAfterPreview,
    handleGenerate,
    apiPostResponse,
    apiGetResponse,
  });

  const { isError } = useRequest({
    handleStopGenerating,
    handleGetPreviewApi,
    apiPostResponse,
    apiGetResponse,
    handleCloseProgress,
  });

  const repeatBackgroundImage = () => {
    if (activeFeatureName === 'background') return '';
    if (!featureData?.single?.param?.isNoBackground) return '';

    return houndstooth.src;
  };

  const [globalViewerMode, setGlobalViewerMode] = useState<GlobalViewerMode>('viewPreview')
  const isHistoryDisabled = useMemo(() => {
    return globalViewerMode === 'selectSupportImage' || globalViewerMode === 'supportForm'
  },[
    globalViewerMode,
  ])
  const isFunctionDisabled = useMemo(() => {
    return globalViewerMode === 'selectSupportImage' || globalViewerMode === 'supportForm'
  },[
    globalViewerMode,
  ])

  return (
    <>
      {activeFeatureName === 'white' && (
        <FeaturesStepLayout
          isHistoryDisabled={isHistoryDisabled}
        >
          <></>
          <></>
        </FeaturesStepLayout>
      )}
      {activeFeatureName !== 'white' && (
        <FeaturesStepLayout
          isHistoryDisabled={isHistoryDisabled}
        >
          <Preview
            repeatBackgroundImage={repeatBackgroundImage()}
            globalViewerMode={globalViewerMode}
            setGlobalViewerMode={setGlobalViewerMode}
          />
          <StyledFunctionBox
            style={{
              filter: isFunctionDisabled ? 'grayscale(1) opacity(0.5)' : 'none',
            }}
          >
            <Functions
              handleResetAfterPreview={handleResetAfterPreview}
              isDisabled={isFunctionDisabled}
            />
            <>
              <Buttons
                handleGenerate={handleGenerate}
                handleClear={handleClear}
                handleResetAfterPreview={handleResetAfterPreview}
                isDisabled={isFunctionDisabled}
              />
            </>
          </StyledFunctionBox>
        </FeaturesStepLayout>
      )}
      {!isError && (
        <ProgressDialog
          isOpen={isOpenProgress}
          title={loadingProgressTitle}
          loadingProgressPercent={loadingProgressPercent}
          handleCloseProgress={handleCloseProgress}
          handleStopGenerating={handleStopGenerating}
        />
      )}
    </>
  );
});
