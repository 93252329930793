/* eslint-disable @typescript-eslint/no-explicit-any */
import { TabButton } from '@/features/components/atoms/TabButton';
import React, { memo } from 'react';
import { MdAutoAwesome } from 'react-icons/md';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { iconWrapperStyle, listStyle, wrapperStyle } from './SwiperSwitch.css';
import { SwiperSwitchKey } from './hooks/useSwiperSwitch';

type Props = {
  variant: 'teal' | 'primary';
  swiperSwitchKey: SwiperSwitchKey;
  setSwiperSwitchKey: React.Dispatch<React.SetStateAction<SwiperSwitchKey>>;
};

export const SwiperSwitch = memo(
  ({
    variant,
    swiperSwitchKey,
    setSwiperSwitchKey,
  }: Props) => {
    const isGeneratedButtonActive = swiperSwitchKey === 'generated';
    const isOriginalButtonActive = swiperSwitchKey === 'original';

    return (
      <ul className={wrapperStyle}>
        <li className={listStyle}>
          <TabButton
            variant={variant}
            onClick={() => {
              setSwiperSwitchKey('generated');
            }}
            label={(
              <div className={iconWrapperStyle}>
                <MdAutoAwesome />
                {/* <span className={isGeneratedButtonActive ? textStyleVariants[variant] : ''}>生成画像</span> */}
                <span>生成画像</span>
              </div>
            )}
            isActive={isGeneratedButtonActive}
          />
        </li>
        <li className={listStyle}>
          <TabButton
            variant={variant}
            onClick={() => {
              setSwiperSwitchKey('original');
            }}
            label={(
              // <span className={isOriginalButtonActive ? textStyleVariants[variant] : ''}>元画像</span>
              <span>元画像</span>
            )}
            isActive={isOriginalButtonActive}
          />
        </li>
      </ul>
    )
  },
);
