import { ActiveTriangle } from '@/components/icons';
import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import {
  StyledBoxRelativeWidthExpanded
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';
import {
  StyledBackgroundResultBox,
  StyledBackgroundResultContent,
  StyledTrianglePosition,
  StyledTriangleWrapper
} from '../../styled';

type ResultTriangleProps = {
  targetType: string | undefined;
};
const ResultTriangle = ({ targetType }: ResultTriangleProps) => {
  return (
    <StyledTriangleWrapper
      trianglePositionType={
        targetType === 'preset'
          ? 'rightOfCenter'
          : targetType === 'preset'
          ? 'right'
          : 'left'
      }
    >
      <StyledTrianglePosition>
        <ActiveTriangle />
      </StyledTrianglePosition>
    </StyledTriangleWrapper>
  );
};

type Props = {
  handleOpenDialog: () => void;
  handleOpenPresetDialog: () => void;
  isDisabled: boolean;
};
export const ResultBox = memo(
  ({
    handleOpenDialog,
    handleOpenPresetDialog,
    isDisabled,
  }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});

    return (
      <div>
        <ResultTriangle targetType={featureData?.single?.param?.targetType} />
        <StyledBackgroundResultBox>
          {featureData?.single?.param?.targetType === 'image' && (
            <StyledBoxRelativeWidthExpanded
              onClick={() => {
                if (isDisabled) return;
                handleOpenDialog()
              }}
            >
              {featureData?.single?.param.currentRef && (
                <ImageWithLoader
                  src={featureData?.single?.param.currentRef}
                  fill
                  alt=""
                  style={{
                    objectFit: 'cover',
                    borderRadius: '4px',
                    padding: '8px',
                    cursor: isDisabled ? 'initial' : 'inherit',
                  }}
                  loaderHeight={8}
                  loaderWidth={8}
                  loaderMode="beater"
                />
              )}
              {!featureData?.single?.param.currentRef && (
                <StyledBackgroundResultContent>
                  クリックして画像を選択
                </StyledBackgroundResultContent>
              )}
            </StyledBoxRelativeWidthExpanded>
          )}
          {featureData?.single?.param?.targetType === 'preset' && (
            <StyledBoxRelativeWidthExpanded
              onClick={() => {
                if (isDisabled) return;
                handleOpenPresetDialog()
              }}
            >
              {featureData?.single?.param?.presetUrl && (
                <ImageWithLoader
                  src={featureData?.single?.param?.presetUrl}
                  fill
                  alt=""
                  style={{
                    objectFit: 'cover',
                    borderRadius: '4px',
                    padding: '8px',
                    cursor: isDisabled ? 'initial' : 'inherit',
                  }}
                  loaderHeight={8}
                  loaderWidth={8}
                  loaderMode="beater"
                />
              )}
              {!featureData?.single?.param?.presetUrl && (
                <StyledBackgroundResultContent>
                  プリセットを選択
                </StyledBackgroundResultContent>
              )}
            </StyledBoxRelativeWidthExpanded>
          )}
        </StyledBackgroundResultBox>
      </div>
    );
  },
);
