import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useError } from '@/hooks/global/useError';
import { PostTasksV2Param } from '@/types/backendApi';
import { useCallback, useState } from 'react';
import {
  useBackgroundParam,
  useColorParam,
  useItemParam,
  useWhiteParam,
} from './postParams';

export const useGenerate = () => {
  const { logger } = useError();
  const { postTasksV2, getTask } = useBackendApi({});
  const { featureData, updateFeatureDataSingle, activeFeatureName } =
    useFeaturesContext({});

  const { getBackgroundSinglePresetParam, getBackgroundRefImageParam } =
    useBackgroundParam();
  const { getColorSingleColorParam, getColorRefImageParam } = useColorParam();
  const { getWhiteColorParam } = useWhiteParam();
  const { getItemRefImageParam } = useItemParam();

  const [apiPostResponse, setPostApiResponse] = useState<Task | undefined>(
    undefined,
  );
  const [apiGetResponse, setGetApiResponse] = useState<Task | undefined>(
    undefined,
  );

  const handlePostPreviewApi = useCallback(async (): Promise<void> => {
    if (!featureData?.mainImage.base64 || !featureData?.mainImage.maskBase64)
      return;

    let postParam: PostTasksV2Param | null = null;
    if (activeFeatureName === 'background') {
      if (featureData?.single?.param?.targetType === 'preset') {
        postParam = getBackgroundSinglePresetParam();
      } else {
        postParam = getBackgroundRefImageParam();
      }
    }
    if (activeFeatureName === 'white') {
      postParam = getWhiteColorParam();
    }
    if (activeFeatureName === 'color') {
      if (featureData?.single?.param?.targetType === 'color') {
        postParam = getColorSingleColorParam();
      } else {
        postParam = getColorRefImageParam();
      }
    }
    if (activeFeatureName === 'item') {
      if (featureData?.single?.param?.targetType === 'image') {
        postParam = getItemRefImageParam();
      }
    }
    if (!postParam) return;
    console.log('post param', postParam);
    try {
      setPostApiResponse(await postTasksV2(postParam));
    } catch (error) {
      console.error(error);
      logger({ error });
    }
  }, [
    featureData?.mainImage.base64,
    featureData?.mainImage.maskBase64,
    featureData?.single?.param?.targetType,
    activeFeatureName,
    getBackgroundSinglePresetParam,
    getBackgroundRefImageParam,
    getWhiteColorParam,
    getColorSingleColorParam,
    getColorRefImageParam,
    getItemRefImageParam,
    postTasksV2,
    logger,
  ]);

  const handleGetPreviewApi = useCallback(async (): Promise<void> => {
    try {
      if (!featureData?.single?.genStatus.isGenerating) return;
      if (featureData?.single?.genStatus.isRequesting) return;
      if (!apiPostResponse?.id) return;
      updateFeatureDataSingle('genStatus', {
        isRequesting: true,
      });
      const res = await getTask(apiPostResponse?.id, {});
      if (res?.result.status === 'FAILED') {
        throw new Error("時間を空けてもう一度実行して下さい。");
      }
      setGetApiResponse(res);
    } catch (error) {
      console.error(error);
      logger({ error });
    }
  }, [
    apiPostResponse?.id,
    featureData?.single?.genStatus.isGenerating,
    featureData?.single?.genStatus.isRequesting,
    getTask,
    updateFeatureDataSingle,
    logger,
  ]);

  return {
    apiPostResponse,
    apiGetResponse,
    handlePostPreviewApi,
    handleGetPreviewApi,
    setPostApiResponse,
    setGetApiResponse,
  };
};
