import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledFlexCenter
} from '@/components/styled';
import { LinearProgress } from '@mui/material';
import { memo, useState } from 'react';
import { StyledConfirmBody, StyledConfirmDialogContainer, StyledProgressText, StyledTipsText, StyledTitle } from './styled';

type Props = {
  title?: string;
  isOpen: boolean;
  loadingProgressPercent: number | null;
};

export const BatchUploadingDialog = memo(
  ({
    title = 'アップロード中です...',
    isOpen,
    loadingProgressPercent = 0,
  }: Props): JSX.Element => {
    const [isConfirmCancel] = useState(false);

    return (
      <Dialog
        isOpenDialog={isOpen}
        // biome-ignore lint/suspicious/noEmptyBlockStatements:
        onClose={() => {}}
        maxWidth="sm"
        dialogTitle=""
        hasCloseIcon={false}
      >
        {!isConfirmCancel && (
          <StyledConfirmDialogContainer>
            <StyledConfirmBody>
              <StyledFlexCenter style={{marginBottom: '24px'}}>
                {loadingProgressPercent ? (
                  <LinearProgress
                    sx={{ width: 200, height: 4 }}
                    variant="determinate"
                    value={loadingProgressPercent}
                  />
                ) : (
                  <LinearProgress sx={{ width: 200, height: 4 }} />
                )}
              </StyledFlexCenter>
              <StyledTitle>{title}</StyledTitle>
              <StyledProgressText>{Math.round(loadingProgressPercent ?? 0)}%&nbsp;完了...</StyledProgressText>
              <StyledTipsText>
                ファイル容量が大きい場合は<br />アップロードに時間がかかります
              </StyledTipsText>
            </StyledConfirmBody>
          </StyledConfirmDialogContainer>
        )}
      </Dialog>
    );
  },
);
