import { _TaskParameters } from '@/features/components/steps/BatchUpload/types';
import Image from 'next/image';
import { Dispatch, SetStateAction, memo, useCallback } from 'react';
import { sampleImageWrapperStyleVariants, switchTextStyleVariants, wrapperStyle } from './NoBackgroundSwitch.css';
import sampleNoBackground from '/public/any/sampleNoBackground.jpg';
import sampleWithBackground from '/public/any/sampleWithBackground.jpg';

type Props = {
  isNoBackground: boolean;
  _setTaskParameters: Dispatch<SetStateAction<_TaskParameters>>
};

// MEMO: 複数背景で利用しているスイッチ。同名のコンポーネントが存在しているが、こちらは従来のcontext管理を使わない仕様。設計を整えながらそのうちこの形式に変えていく。
export const NoBackgroundSwitch = memo(
  ({
    isNoBackground,
    _setTaskParameters,
  }: Props): JSX.Element => {
    const handleClickButton = useCallback((value: boolean) => {
      _setTaskParameters((prev) => ({
        ...prev,
        isNoBackground: value,
      }));
    }, [
      _setTaskParameters,
    ])

    return (
      <div className={wrapperStyle}>
        {/* 透過OFFボタン */}
        <button
          type='button'
          onClick={() => handleClickButton(false)}
        >
          <div
            className={!isNoBackground ? sampleImageWrapperStyleVariants.isActive : sampleImageWrapperStyleVariants.isNotActive }
          >
            <Image
              src={sampleWithBackground}
              alt=""
              width={160}
              height={160}
            />
          </div>
          <span
            className={!isNoBackground ? switchTextStyleVariants.isActive : switchTextStyleVariants.isNotActive}
          >背景白抜き</span>
        </button>

        {/* 透過ONボタン */}
        <button
          type='button'
          onClick={() => handleClickButton(true)}
        >
          <div
            className={isNoBackground ? sampleImageWrapperStyleVariants.isActive : sampleImageWrapperStyleVariants.isNotActive }
          >
            <Image
              src={sampleNoBackground}
              alt=""
              width={160}
              height={160}
            />
          </div>
          <span
            className={isNoBackground ? switchTextStyleVariants.isActive : switchTextStyleVariants.isNotActive}
          >背景なし（透明）</span>
        </button>
      </div>
    );
  },
);
