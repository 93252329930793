import { Button, ButtonProps } from '@mui/material';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type radioBoxProps = {
  width?: string | number;
  isEnable?: boolean;
  isActive?: boolean;
  variant?: 'teal' | 'primary';
} & Omit<ButtonProps, 'variant'>;

export const StyledTabButton = memo(
  ({
    isActive,
    variant = 'primary',
    children,
    ...props
  }: radioBoxProps) => {

    return (
      <Button
        type="button"
        classes={{root: isActive ? vanilla.tabButtonstyleVariants[variant] : vanilla.tabButtonstyleVariants.isNotActive}}
        {...props}
      >
        {children}
      </Button>
    );
  },
);
