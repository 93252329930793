import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Tooltip } from '@/components/atoms/Tooltip';
import CheckFilled from '@/components/icons/export/CheckFilled';
import {
  StyledFlex,
  StyledFlexCenter,
  StyledFlexEnd,
  StyledFlexGap10,
} from '@/components/styled';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import {
  StyledTableRowContainer,
  StyledTableRowName,
  StyledTableRowNameEllipsis,
  StyledTableRowNameFlex,
} from '@/features/components/styled/batch';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { IconButton, LinearProgress } from '@mui/material';
import { Dispatch, SetStateAction, memo, useMemo } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { StyledCircleImage, StyledProgressContainer } from '../styled';

type GenStatus = {
  isGenerating: boolean;
  isGenerated: boolean;
  isFailed: boolean;
};

type Props = {
  index: number
  set: FeatureBatch;
  batchId: string;
  handleOpenDialog: () => void;
  apiPostResponse: Task | undefined;
  apiGetResponse: Task | undefined;
  isDownloaded: boolean
  isInquired: boolean
  genStatus: GenStatus;
  handleUpdateTaskAndBatchId: () => void;
  isSelectedColumn: boolean;
  setSelectedColumnIndex: Dispatch<SetStateAction<number | undefined>>;
};

/**
 * @see バッチダウンロードの生成状態とダウンロード実行ボタンを表示するコンポーネント
 */
export const ColorTemperatureRow = memo(
  ({
    index,
    set,
    batchId,
    handleOpenDialog,
    apiPostResponse,
    apiGetResponse,
    isDownloaded,
    isInquired,
    genStatus,
    handleUpdateTaskAndBatchId,
    isSelectedColumn,
    setSelectedColumnIndex,
  }: Props): JSX.Element => {
    const { convertToDateTime } = useConvertDate();

    const isRowClickable = useMemo(() => {
      return apiGetResponse?.result.status === 'COMPLETED'
    }, [apiGetResponse?.result.status])

    return (
      <>
        {apiPostResponse && apiGetResponse && apiGetResponse.result && (
          <StyledTableRowContainer
            sx={{
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              backgroundColor:
                isSelectedColumn ? '#2196F314'
                  : apiGetResponse.result.status === 'IN_QUEUE' ||
                    apiGetResponse.result.status === 'IN_PROGRESS' ||
                    apiGetResponse.result.status === 'COMPLETED'
                      ? 'var(--color-gray-white)'
                      : batchId === set.id
                      ? '#fdeded'
                      : 'rgba(0, 0, 0, 0.05)',
              cursor: isRowClickable ? 'pointer' : 'inherit',
              '&:hover': {
                backgroundColor: isSelectedColumn ? '#2196F314'
                  : isRowClickable ? '#f8f8f8' : 'inherit',
              },
            }}
            onClick={() => {
              handleUpdateTaskAndBatchId(); // ここでtaskApiResponseを更新している

              if (!isRowClickable) return
              setSelectedColumnIndex(index);
              handleOpenDialog();
            }}
          >
            <StyledTableRowName>
              <StyledTableRowNameFlex>
                <StyledFlexGap10>
                  <StyledCircleImage
                    src={`${set.refImage.refUrl}&d=56x56`}
                    width={56}
                    height={56}
                    alt={`${set.refImage.refUrl}`}
                  />
                  <StyledTableRowNameEllipsis>
                    <Tooltip title={set.setName} placement="bottom-start">
                      {set.setName}
                    </Tooltip>
                  </StyledTableRowNameEllipsis>
                </StyledFlexGap10>
              </StyledTableRowNameFlex>
            </StyledTableRowName>
            <Box
              style={{
                minWidth: 150,
                whiteSpace: 'nowrap',
              }}
            >
              {set.uploadedAt
                ? convertToDateTime(set.uploadedAt as unknown as string)
                : 'ー'}
            </Box>
            <StyledFlexCenter style={{ minWidth: 150 }}>
              {set.originalImages.length || 'ー'}
            </StyledFlexCenter>
            <Box style={{ minWidth: 'calc(100% - 700px)' }}>
              <StyledProgressContainer>
                {!apiGetResponse.result.progress &&
                !genStatus.isGenerated &&
                !genStatus.isFailed ? (
                  <>
                    <LinearProgress
                      sx={{ width: '100%', height: 4 }}
                      color="secondary"
                    />
                    <Box sx={{ paddingLeft: '10px', fontSize: 14 }}>待機中</Box>
                  </>
                ) : apiGetResponse.result.progress &&
                  apiGetResponse.result.progress < 1 &&
                  !genStatus.isGenerated &&
                  !genStatus.isFailed ? (
                  <>
                    <LinearProgress
                      sx={{ width: '100%', height: 4 }}
                      variant="determinate"
                      value={Math.round(
                        Number(apiGetResponse.result.progress) * 100,
                      )}
                    />
                    <Box sx={{ paddingLeft: '10px', fontSize: 14 }}>
                      {Math.round(apiGetResponse.result.progress * 100)}%
                    </Box>
                  </>
                ) : (
                  <>
                    <StyledFlex>
                      {apiGetResponse.result.status === 'COMPLETED' && (
                        <Box
                          sx={{
                            fontSize: 14,
                          }}
                        >
                          生成完了
                        </Box>
                      )}
                      {apiGetResponse.result.status === 'FAILED' && (
                        <Box
                          sx={{
                            fontSize: 14,
                            color: 'var(--color-danger-main)',
                          }}
                        >
                          生成失敗
                        </Box>
                      )}
                      {apiGetResponse.result.status === 'TIMEOUT' && (
                        <Box
                          sx={{
                            fontSize: 14,
                            color: 'var(--color-danger-main)',
                          }}
                        >
                          生成失敗
                        </Box>
                      )}
                      <div>
                        { isDownloaded &&
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0,
                            flexWrap: 'nowrap',
                            marginLeft: '16px',
                          }}>
                            <CheckFilled width={20} height={20} fill="#2196F3" />
                            <span style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '166%',
                              letterSpacing: '0.4px',
                              color: 'var(--color-text-secondary)',
                            }}>ダウンロード</span>
                          </div>
                        }
                        { isInquired &&
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0,
                            flexWrap: 'nowrap',
                            marginLeft: '16px',
                          }}>
                            <CheckFilled width={20} height={20} fill="var(--color-teal-400)" />
                            <span style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '166%',
                              letterSpacing: '0.4px',
                              color: 'var(--color-text-secondary)',
                            }}>サポート</span>
                          </div>
                        }
                      </div>
                    </StyledFlex>
                    {isRowClickable && (
                      <StyledFlexEnd style={{ gap: '30px' }}>
                        <IconButton
                          style={{ color: 'var(--color-primary-main)' }}
                          onClick={handleOpenDialog}
                        >
                          <MdFileDownload size={24} />
                        </IconButton>
                      </StyledFlexEnd>
                    )}
                  </>
                )}
              </StyledProgressContainer>
            </Box>
          </StyledTableRowContainer>
        )}
      </>
    );
  },
);
