import { Button } from '@/components/atoms/Button';
import { Dialog } from '@/components/molecules/Dialog';
import { Dispatch, SetStateAction, memo } from 'react';
import { _TaskParameters } from '../../steps/BatchUpload/types';
import { contentWrapperStyle, titleStyle } from './ConfirmBackgroundDialog.css';
import { NoBackgroundSwitch } from './NoBackgroundSwitch/NoBackgroundSwitch';

type Props = {
  isOpen: boolean;
  handleSubmitDialog: () => void;
  isNoBackground: boolean;
  _setTaskParameters: Dispatch<SetStateAction<_TaskParameters>>;
};

// 透過するかどうか選択する確認ダイアログ
export const ConfirmBackgroundDialog = memo(
  ({
    isOpen,
    handleSubmitDialog,
    isNoBackground,
    _setTaskParameters,
  }: Props): JSX.Element => {
    return (
      <Dialog
        isOpenDialog={isOpen}
        maxWidth="sm"
        dialogTitle=""
        hasCloseIcon={false}
      >
        <div className={contentWrapperStyle}>
          <span className={titleStyle}>背景を選んでください</span>

          <NoBackgroundSwitch
            isNoBackground={isNoBackground}
            _setTaskParameters={_setTaskParameters}
          />
          <Button
            style={{
              fontSize: 15,
            }}
            onClick={handleSubmitDialog}
          >
            決定してアップロード
          </Button>
        </div>
      </Dialog>
    );
  },
);
