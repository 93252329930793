import { Box } from '@/components/atoms/Box';
import { Z_INDEX } from '@/constants';
import { useClickAway } from '@/hooks/global/useClickAway';
import { IconButton, Popper as MuiPopper, PopperProps } from '@mui/material/';
import { memo, useRef } from 'react';
import { MdClose } from 'react-icons/md';
import {
  StyledCloseButton,
  StyledHead,
  StyledPopper,
  StyledYscrollBox,
} from './styled';

type Props = {
  title?: string;
  hasTitle?: boolean;
  titleSize?: number | string;
  titleWeight?: number | string;
  titlePosition?: 'start' | 'center' | 'end';
  anchorElement?: HTMLElement | null;
  children: React.ReactNode;
  hasYscroll?: boolean;
  hasClose?: boolean;
  onClose: () => void;
  placement?: PopperProps['placement'];
  placementY?: number;
  boxShadow?: string;
  borderRadius?: string;
  paddingRight?: string;
  backgroundColor?: string;
  closeIconSize?: number;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  zIndex?: number;
};

// MEMO: 外部をクリックしたときのイベントを制御するため、表示しないときは常にマウントしないように記述すること。
// TODO: そもそももっとスマートなコンポーネントにできないか検討する
export const Popper = memo(
  ({
    title,
    hasTitle = true,
    titleSize = '16px',
    titleWeight = 'normal',
    titlePosition = 'start',
    anchorElement = null,
    children,
    hasYscroll = true,
    hasClose = true,
    onClose,
    placement = 'bottom',
    placementY = 0,
    boxShadow = '0px 0px 40px rgba(0, 0, 0, 0.17)',
    borderRadius = '4px',
    paddingRight = '0px',
    backgroundColor = 'var(--color-gray-white)',
    closeIconSize = 18,
    onMouseOver,
    onMouseOut,
    zIndex = Z_INDEX.popperDefault,
  }: Props) => {
    const popperRef = useRef(null);
    /*
    const judgeClickAway = useCallback(
      // biome-ignore lint/suspicious/noExplicitAny: あとで修正
      (e: any) => {
        console.log(e.target.className);
        if (!e.target.className.match) return;
        if (e.target.className) {
          if ((e.target as HTMLElement).className.match(/MuiBox/)) {
            onClose();
          }
        }
      },
      [onClose],
    );
    */
    useClickAway({
      ref: popperRef,
      onClickAway: onClose,
    });

    return (
      <MuiPopper
        open
        anchorEl={anchorElement}
        placement={placement}
        style={{
          zIndex,
          paddingTop: `${placementY}px`, // MEMO: paddingを与える仕様のだとpaddingの箇所をクリックしてもPopperが消えないため、MUI廃止時に別のやり方で実現させる。
          paddingRight,
        }}
        ref={popperRef}
      >
        <StyledPopper
          style={{
            boxShadow,
            borderRadius,
            backgroundColor,
          }}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          {hasTitle && hasClose && (
            <StyledHead
              titleSize={titleSize}
              titleWeight={titleWeight}
              titlePosition={titlePosition}
            >
              <Box>{title}</Box>
              <StyledCloseButton hasTitle>
                <IconButton onClick={onClose}>
                  <MdClose size={closeIconSize} />
                </IconButton>
              </StyledCloseButton>
            </StyledHead>
          )}
          {!hasTitle && hasClose && (
            <StyledCloseButton hasTitle={hasTitle}>
              <IconButton onClick={onClose}>
                <MdClose size={closeIconSize} />
              </IconButton>
            </StyledCloseButton>
          )}
          {hasYscroll && <StyledYscrollBox>{children}</StyledYscrollBox>}
          {!hasYscroll && <>{children}</>}
        </StyledPopper>
      </MuiPopper>
    );
  },
);
