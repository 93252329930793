import { Box } from '@/components/atoms/Box';
import { StyledFlex } from '@/components/styled';
import { UploadRef } from '@/features/components/steps/BatchUpload/UploadRef';
import { UploadSet } from '@/features/components/steps/BatchUpload/UploadSet';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType, _TaskParameters } from '@/features/components/steps/BatchUpload/types';
import { memo, useMemo } from 'react';

type Props = {
  targetId: string;
  targetNumber: number;
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
  variant: 'singleSet' | 'multiSet';
  _taskParameters: _TaskParameters;
};

export const Uploader = memo(
  ({
    targetId,
    targetNumber,
    setFunctionViewType,
    warningFunction,
    variant,
    _taskParameters,
  }: Props): JSX.Element => {
    const { batchConstant, currentNumber } = useBatchUpload();

    const backgroundColor = useMemo(() => {
      switch (variant) {
        case 'singleSet':
          return '#fff';
        case 'multiSet':
          return currentNumber === targetNumber ? '#F5FAFF' : '#fff';
        default:
          break;
      }
    }, [
      currentNumber,
      targetNumber,
      variant,
    ]);

    const uploaderHeight = useMemo(() => {
      switch (variant) {
        case 'singleSet':
          return '360px'
        case 'multiSet':
          return '240px'
        default:
          break;
      }
    }, [variant])

    return (
      <>
        <Box
          style={{
            borderTop: '1px solid #ddd',
            display: 'grid',
            padding: '16px 24px 16px 24px',
            backgroundColor,
          }}
        >
          <StyledFlex style={{
            gap: '24px',
            alignItems: 'flex-start',
            height: uploaderHeight,
          }}>
            {batchConstant && batchConstant.hasUploadRef && (
              <UploadRef
                targetId={targetId}
                targetNumber={targetNumber}
                warningFunction={warningFunction}
              />
            )}
            <UploadSet
              targetId={targetId}
              targetNumber={targetNumber}
              setFunctionViewType={setFunctionViewType}
              warningFunction={warningFunction}
              _taskParameters={_taskParameters}
            />
          </StyledFlex>
        </Box>
      </>
    );
  },
);
