import { Box } from '@/components/atoms/Box';
import { StyledFlexEnd, StyledGridEnd } from '@/components/styled';
import { BATCH_LIMIT_SET } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { StepExplain } from '@/features/components/atoms/StepExplain';
import { StyledExplainBox } from '@/features/components/atoms/StepExplain/styled';
import { BatchUploadingDialog } from '@/features/components/modals/BatchUploadingDialog';
import { ConfirmEraseDataDialog } from '@/features/components/modals/ConfirmEraseDataDialog';
import { useConfirmEraseData } from '@/features/components/modals/ConfirmEraseDataDialog/hooks/useConfirmEraseData';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesBatchStepLayout } from '@/features/components/templates/Layout/FeaturesBatchStepLayout';
import { useDialog } from '@/hooks/global/useDialog';
import { useUploadingProgress } from '@/hooks/global/useUploadingProgress';
import { Divider } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { ConfirmBackgroundDialog } from '../../modals/ConfirmBackgroundDialog';
import { useConfirmBackgroundDialog } from '../../modals/ConfirmBackgroundDialog/hooks/useConfirmBackgroundDialog';
import { useBatchOptionalContext } from '../hooks/useBatchOptionalContext';
import { AddSetButton } from './AddSetButton';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { NoticeLimitSet } from './NoticeLimitSet';
import { SetTable } from './SetTable';
import { UploadRule } from './UploadRule';
import { useBatchUpload } from './hooks/useBatchUpload';
import { useBatchUploadWarnings } from './hooks/useBatchUploadWarnings';
import { FunctionViewType, _TaskParameters } from './types';

// featuresディレクトリの中にconstantsディレクトリを作成したいが、そもそもトップレベルコンポーネントが複数のfeaturesで使われているのでいったんそちらの設計から見直す必要がある。
const _initialTaskParameters: _TaskParameters = {
  isNoBackground: false,
}

/**
 * @see バッチアップロードのメインコンポーネント
 */
export const BatchUpload = memo((): JSX.Element => {
  const {
    isOpenDialog: isConfirmBackgroundDialog,
    handleSubmit: handleSubmitConfirmBackgroundDialog,
    handleReset: handleResetConfirmBackgroundDialog,
  } = useConfirmBackgroundDialog();

  // MEMO: 元々contextで管理しているパラメーターをstateで管理してみるテスト。ここで実験しつつ全体の設計方針を整える。
  const [_taskParameters, _setTaskParameters] = useState<_TaskParameters>(_initialTaskParameters)

  // MEMO: リセット処理。本来は必要ないがcontextのスコープ管理がなされていないので、明示的にリセットする必要がある。
  useEffect(() => {
    return () => {
      handleResetConfirmBackgroundDialog();
      _setTaskParameters(_initialTaskParameters);
    }
  }, [
    handleResetConfirmBackgroundDialog,
  ])

  const { activeFeatureName } = useFeaturesContext({});
  const { warningsData, warningFunction } = useBatchUploadWarnings();
  const {
    featureData,
    currentNumber,
    isBatchLimitReached,
    batchConstant,
  } = useBatchUpload();
  const [functionViewType, setFunctionViewType] =
    useState<FunctionViewType>('');
  const { isOpenDialog } = useDialog();
  const { uploadingProgress } = useUploadingProgress();

  const {
    isOpenConfirmEraseDataDialog,
    handleCloseConfirmEraseDataDialog,
    handleSubmitEraseDialog,
  } = useConfirmEraseData();

  const isDisplayButtons = useMemo(() => {
    return activeFeatureName !== 'multiWhite'
  }, [activeFeatureName]);

  // MEMO: 元々useBatchUploadで実行されていたものを機能TOPであるここのコンポーネントに持ってきた（構造上useBatchUploadが頻繁に再マウントされる問題があるため）
  const { initializeBatchOptionalContext } = useBatchOptionalContext();
  useEffect(() => {
    initializeBatchOptionalContext();
  }, [initializeBatchOptionalContext]);

  return (
    <>
      <FeaturesBatchStepLayout>
        <StyledExplainBox>
          <>
            <StepExplain />
            <StyledFlexEnd>
              {batchConstant?.addableSet &&
                <AddSetButton
                  resetWarnings={warningFunction.resetWarnings}
                  isBatchLimitReached={isBatchLimitReached}
                />
              }
            </StyledFlexEnd>
          </>
        </StyledExplainBox>
        <Box>
          {isBatchLimitReached && (
            <NoticeLimitSet BATCH_LIMIT_SET={BATCH_LIMIT_SET} />
          )}
          <SetTable
            setFunctionViewType={setFunctionViewType}
            warningFunction={warningFunction}
            _taskParameters={_taskParameters}
          />
          <Divider />
          <Box style={{ display: 'grid', gap: '32px', padding: '24px' }}>
            {featureData &&
            featureData.batch.length > 0 &&
            (featureData?.batch[currentNumber]?.originalImages.length > 0 ||
              featureData?.batch[currentNumber].refSam.combinedBase64) ? (
              ''
            ) : (
              <UploadRule />
            )}
          </Box>
        </Box>
        <StyledFunctionBox sx={{ height: '100%' }}>
          <Functions
            functionViewType={functionViewType}
            warningsData={warningsData}
          />
          { isDisplayButtons && (
            <StyledGridEnd>
              <Buttons />
            </StyledGridEnd>
          )}
        </StyledFunctionBox>
      </FeaturesBatchStepLayout>
      <BatchUploadingDialog
        isOpen={isOpenDialog}
        loadingProgressPercent={uploadingProgress}
      />
      <ConfirmBackgroundDialog
        isOpen={isConfirmBackgroundDialog}
        handleSubmitDialog={handleSubmitConfirmBackgroundDialog}
        isNoBackground={_taskParameters.isNoBackground}
        _setTaskParameters={_setTaskParameters}
      />
      <ConfirmEraseDataDialog
        isOpenDialog={isOpenConfirmEraseDataDialog}
        handleCloseDialog={handleCloseConfirmEraseDataDialog}
        handleSubmit={handleSubmitEraseDialog}
      />
    </>
  );
});
