import { Box } from '@/components/atoms/Box';
import { StyledFlexCenter, StyledGridGap25 } from '@/components/styled';
import { StyledFunctionCompleteButton } from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';
import {
  styledVerticalAlignBottom,
  styledVerticalAlignNone,
} from './styled/vanilla.css';

type Props = {
  isDialog?: boolean;
  isDisabled: boolean;
};

export const Buttons = memo(
  ({
    isDialog,
    isDisabled,
  }: Props): JSX.Element => {
    const { activateTargetFeature } = useFeaturesContext({});

    return (
      <>
        <StyledFlexCenter>
          <Box
            className={
              !isDialog ? styledVerticalAlignBottom : styledVerticalAlignNone
            }
          >
            <StyledGridGap25>
              {!isDialog && (
                <>
                  <StyledFunctionCompleteButton
                    disabled={isDisabled}
                    onClick={() => {
                      activateTargetFeature('home');
                    }}
                  >
                    このタスクを終了する
                  </StyledFunctionCompleteButton>
                </>
              )}
              <Box />
            </StyledGridGap25>
          </Box>
        </StyledFlexCenter>
      </>
    );
  },
);
