import { StyledGridEnd, StyledGridGap10 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { FileDetail } from '@/features/components/atoms/FileDetail';
import { GlobalSegmentation } from '@/features/components/organisms/GlobalSegmentation';
import { Buttons } from '@/features/components/steps/Segmentation/Buttons';
import { Functions } from '@/features/components/steps/Segmentation/Functions';
import { useInitializeSamType } from '@/features/components/steps/Segmentation/hooks/useInitializeSamType';
import {
  StyledPreview,
  StyledPreviewSlide,
} from '@/features/components/styled/preview';
import { FeaturesModalStepLayout } from '@/features/components/templates/Layout/FeaturesModalStepLayout';
import { memo } from 'react';
import { useHandlers } from '../hooks/useHandlers';
import { StyledSegmentationFunctionBox } from '../styled';

type Props = {
  field: ImageFields;
  onSubmit: () => void;
  isBatch?: boolean;
};

/**
 * JSDoc
 * @see セグメンテーションステップ
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Segmentation = memo(
  ({ field, onSubmit, isBatch = false }: Props): JSX.Element => {
    const { handleResetImageFromModal } = useHandlers();
    useInitializeSamType({ field });
    const { featureData, updateFeatureData } = useFeaturesContext({});

    return (
      <>
        <FeaturesModalStepLayout>
          <StyledGridGap10>
            <StyledPreview swiperIndex={0} maxIndex={0} width={660} height={600}>
              <StyledPreviewSlide
                width={512}
                height={600}
              >
                {featureData && (
                  <GlobalSegmentation
                    field={field}
                    data={featureData}
                    updateData={updateFeatureData}
                  />
                )}
              </StyledPreviewSlide>
            </StyledPreview>
          </StyledGridGap10>
          <StyledSegmentationFunctionBox>
            <Functions field={field} isBatch={isBatch} />
            {/* 暫定対応・色温度ではファイル情報を表示しない */}
            {!isBatch ? (
              <FileDetail
                field={field}
                onResetImage={handleResetImageFromModal}
                onDialog
              />
            ) : (
              <></>
            )}

            <StyledGridEnd
              style={{
                position: 'absolute',
                bottom: 0,
                paddingLeft: '20px',
              }}
            >
              <Buttons field={field} onSubmit={onSubmit} />
            </StyledGridEnd>
          </StyledSegmentationFunctionBox>
        </FeaturesModalStepLayout>
      </>
    );
  },
);
