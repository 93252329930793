import { useCallback, useEffect } from 'react';
import { Swiper as SwiperType } from 'swiper/types';

type Props = {
  swiperRef: SwiperType | null;
  swiperRef2: SwiperType | null;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
};
export const useSwiperState = ({
  swiperRef,
  swiperRef2,
  swiperIndex,
  setSwiperIndex,
}: Props) => {
  const handleGetActiveSwiperIndex = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: あとで修正
    (swiper: any) => {
      setSwiperIndex(swiper.realIndex);
    },
    [setSwiperIndex],
  );

  const handleChangeSlide = useCallback(
    (i: number) => {
      if (!swiperRef || !swiperRef2) return;
      swiperRef.slideToLoop(i);
      swiperRef2.slideToLoop(i);
      setSwiperIndex(i);
    },
    [
      setSwiperIndex,
      swiperRef,
      swiperRef2,
    ],
  );

  useEffect(() => {
    if (!swiperRef || !swiperRef2) return;
    if (swiperIndex) return;
    swiperRef.slideToLoop(0);
    swiperRef2.slideToLoop(0);
    setSwiperIndex(0);
  }, [
    setSwiperIndex,
    swiperIndex,
    swiperRef,
    swiperRef2,
  ]);

  return {
    handleGetActiveSwiperIndex,
    handleChangeSlide,
  };
};
