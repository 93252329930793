import { ActiveTriangle } from '@/components/icons';
import {
  StyledBoxRelativeWidthExpanded,
  StyledFlexCenter
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import {
  StyledBackgroundResultBox,
  StyledBackgroundResultContent,
  StyledSettingResultImage,
  StyledTrianglePosition,
  StyledTriangleWrapper,
} from '../../styled';

const ResultTriangle = () => {
  const { featureData } = useFeaturesContext({});

  return (
    <StyledTriangleWrapper
      trianglePositionType={
          featureData?.single?.param?.currentRef &&
          featureData.single.param?.targetType === 'image' ? 'rightOfCenter'
          : featureData?.single.param?.targetType === 'image' ? 'right'
          : 'left'
      }
    >
      <StyledTrianglePosition>
        <ActiveTriangle />
      </StyledTrianglePosition>
    </StyledTriangleWrapper>
  );
};

type Props = {
  handleOpenPopper: (e: React.MouseEvent<HTMLElement>) => void;
  handleOpenDialog: () => void;
  isDisabled: boolean;
};
export const ResultBox = ({
  handleOpenPopper,
  handleOpenDialog,
  isDisabled,
}: Props): JSX.Element => {
  const { featureData } = useFeaturesContext({});

  return (
    <div>
      <ResultTriangle />
      <StyledBackgroundResultBox
        isDisabled={isDisabled}
        width={
          featureData?.single?.param?.currentRef &&
          featureData.single.param?.targetType === 'image'
            ? 152
            : '100%'
        }
        height={
          featureData?.single?.param?.currentRef &&
          featureData.single.param?.targetType === 'image'
            ? 152
            : 90
        }
        padding={
          featureData?.single?.param?.currentRef &&
          featureData.single.param?.targetType === 'image'
            ? '10px'
            : 0
        }
      >
        {featureData?.single?.param?.targetType === 'color' && (
          <StyledBoxRelativeWidthExpanded
            onClick={(e) => {
              if (isDisabled) return;

              handleOpenPopper(e);
            }}
          >
            <StyledFlexCenter sx={{backgroundColor: 'var(--color-primary-pale)'}}>
              {featureData?.single?.param?.currentColor && (
                <StyledBackgroundResultContent
                  width={240}
                  backgroundColor={
                    featureData.single.param.pickColor
                      ? featureData.single.param.pickColor
                      : featureData?.single?.param.currentColor
                  }
                />
              )}
              {!featureData?.single?.param?.currentColor && (
                <StyledBackgroundResultContent
                  width={240}
                >
                  クリックして色を選択
                </StyledBackgroundResultContent>
              )}
            </StyledFlexCenter>
          </StyledBoxRelativeWidthExpanded>
        )}
        {featureData?.single?.param?.targetType === 'image' && (
          <StyledBoxRelativeWidthExpanded onClick={() => {
            if (isDisabled) return;

            handleOpenDialog();
          }}>
            <StyledFlexCenter>
              {featureData?.single?.param?.currentRef && (
                <StyledSettingResultImage
                  src={featureData?.refImage?.combinedBase64}
                  width={136}
                  height={136}
                  alt="pict"
                />
              )}
              {!featureData?.single?.param?.currentRef && (
                <StyledBackgroundResultContent width={240}>
                  クリックして画像を選択
                </StyledBackgroundResultContent>
              )}
            </StyledFlexCenter>
          </StyledBoxRelativeWidthExpanded>
        )}
      </StyledBackgroundResultBox>
    </div>
  );
};
