import { StyledFlexStart } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { AnimatePresence } from 'framer-motion';
import { Fragment, memo, useEffect, useState } from 'react';
import { HistoryMotionToggle } from './HistoryMotionToggle';
import { ThumbnailWithNumber } from './ThumbnailWithNumber';
import {
  StyledHistoryBar,
  StyledHistoryBarThumbnailGrid,
  StyledHistoryMotionContainer,
} from './styled';

type Props = {
  isActive: boolean;
  onClick: () => void;
  isDisabled: boolean;
};

export const HistoryTab = memo(({
  isActive,
  onClick,
  isDisabled,
}: Props): JSX.Element => {
  const { featureData, featureStep, findActiveStep } = useFeaturesContext({});
  const [activeStepName, setActiveStepName] = useState('');

  useEffect(() => {
    setActiveStepName(findActiveStep()?.name || '');
  }, [findActiveStep]);

  return (
    <AnimatePresence initial={isActive}>
      <HistoryMotionToggle isActive={isActive} onClick={onClick} />
      <StyledHistoryMotionContainer
        key="container"
        initial={{ bottom: 0 }}
        animate={{ bottom: isActive ? 0 : -88, opacity: isActive ? 1 : 0 }}
        transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
      >
        <StyledHistoryBar>
          <StyledHistoryBarThumbnailGrid>
            <StyledFlexStart
              style={{
                gap: '0 8px',
              }}
            >
              {featureStep?.map((step, i) => (
                <Fragment key={step.name}>
                  <ThumbnailWithNumber
                    step={step}
                    num={i + 1}
                    activeStepName={activeStepName}
                    isDisabled={isDisabled}
                  />
                </Fragment>
              ))}
            </StyledFlexStart>
          </StyledHistoryBarThumbnailGrid>
        </StyledHistoryBar>
      </StyledHistoryMotionContainer>
    </AnimatePresence>
  );
});
