import { Box } from '@/components/atoms/Box';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { memo, useEffect, useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { GlobalViewer, GlobalViewerMode } from '../GlobalViewer';
import { accordionToggleButtonStyle, normalTextStyle, tealTextStyle } from './GlobalDownloader.css';
import { Label } from './Label/Label';
import { useSupport } from './hooks/useSupport';

type Props = {
  isGenerated: boolean;
  imageResults: ImageResult[];
  setFiles: React.Dispatch<React.SetStateAction<string[]>>;
  repeatBackgroundImage?: string;
  hasCheckIcon?: boolean;
  taskType?: string;
  globalViewerMode: GlobalViewerMode;
  setGlobalViewerMode: React.Dispatch<React.SetStateAction<GlobalViewerMode>>;
};

export const GlobalDownloader = memo(
  ({
    isGenerated,
    imageResults,
    setFiles,
    repeatBackgroundImage,
    hasCheckIcon = true,
    taskType,
    globalViewerMode,
    setGlobalViewerMode,
  }: Props) => {
    const { featureData } = useFeaturesContext({});

    const {
      checkArray,
      setCheckArray,
      checkedCount,
    } = useCheckArray();

    useEffect(() => {
      setCheckArray(imageResults.map(() => true));
    }, [imageResults, setCheckArray]);

    useEffect(() => {
      if (!imageResults.length) return;
      const urls = imageResults.map(
        (imageResult: ImageResult) => imageResult.url,
      );
      setFiles(urls);
    }, [imageResults, setFiles]);

    const {
      handleClickSupportStartButton,
      handleClickSupportCancelButton,
      handleClickApplySupportWithSelectImageButton
    } = useSupport({
      setGlobalViewerMode
    });

    // TODO: Preview.tsxとかぶっている箇所が多いので共通化する
    const filteredImages = useMemo(() => {
      switch (globalViewerMode) {
        case 'viewPreview':
          return imageResults
        case 'selectDownloadImage':
          return imageResults
        case 'selectSupportImage':
          return imageResults
        case 'supportForm':
          return imageResults.filter((_, index) => checkArray[index])
        default:
          throw new Error("Invalid globalViewerMode");
      }
    }, [
      imageResults,
      checkArray,
      globalViewerMode,
    ]);

    const isDisplayAcoordion = useMemo(() => {
      return globalViewerMode === 'supportForm' && checkedCount >= 5
    }, [
      globalViewerMode,
      checkedCount,
    ])

    const [isOpenSupportAccordion, setIsOpenSupportAccordion] = useState(false)

    const label = useMemo(() => {
      return (
        <>
          <Label
            globalViewerMode={globalViewerMode}
            handleClickSupportStartButton={handleClickSupportStartButton}
            handleClickSupportCancelButton={handleClickSupportCancelButton}
          />
          {globalViewerMode === 'supportForm' && (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '17px',
            }}>
              <span className={normalTextStyle}>
                選択した画像
                {checkedCount ? `（${checkedCount}）` : ''}
              </span>
              { isDisplayAcoordion &&
                <button
                  type="button"
                  className={accordionToggleButtonStyle}
                  onClick={() => setIsOpenSupportAccordion(!isOpenSupportAccordion)}
                >
                  { isOpenSupportAccordion ?
                  <>
                    <span className={tealTextStyle}>
                      表示を減らす
                    </span>
                    <MdExpandLess
                      size={16}
                      color="var(--color-teal-400)"
                    />
                  </>
                  :
                  <>
                    <span className={tealTextStyle}>
                      すべての画像を表示
                    </span>
                    <MdExpandMore
                      size={16}
                      color="var(--color-teal-400)"
                    />
                  </>
                  }
                </button>
              }
            </div>
          )}
        </>
      )
    }, [
      globalViewerMode,
      handleClickSupportCancelButton,
      handleClickSupportStartButton,
      checkedCount,
      isDisplayAcoordion,
      isOpenSupportAccordion,
    ])

    useEffect(() => {
      setCheckArray(imageResults.map(() => true));
    }, [imageResults, setCheckArray]);

    return (
      <Box
        sx={{
          position: 'relative',
          verticalAlign: 'top',
        }}
      >
        {!isGenerated ? (
          <></>
        ) : (
          <GlobalViewer
            imageResults={filteredImages}
            hasAnimation={false}
            hasCheckIcon={hasCheckIcon}
            globalViewerMode={globalViewerMode}
            taskId={featureData?.single?.genResult.taskId}
            isOpenSupportAccordion={isOpenSupportAccordion}
            repeatBackgroundImage={repeatBackgroundImage}
            label={label}
            taskType={taskType}
            checkedCount={checkedCount}
            handleClickSupportCancelButton={handleClickSupportCancelButton}
            handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
          />
        )}
      </Box>
    );
  },
);
