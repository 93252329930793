import { TaskOriginalImage } from '@/api/schemas';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { SwiperThumbs } from '@/features/components/organisms/DownloadDialogViewer/SwiperThumbs';
import { UseQueryResult } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import React, { memo, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import type { Swiper as SwiperType } from 'swiper/types';
import type { DownloadDialogMode } from '../../modals/DownloadDialog';
import { Swiper } from './Swiper';
import { SwiperSwitch } from './SwiperSwitch/SwiperSwitch';
import { SwiperSwitchKey } from './SwiperSwitch/hooks/useSwiperSwitch';
import {
  StyledGlobalViewerContainer,
  StyledSwiperMotionDiv,
  StyledSwiperThumbsMotionDiv,
} from './styled';

type Props = {
  originalImages: TaskOriginalImage[];
  imageResults: ImageResult[];
  label?: React.ReactNode;
  hasAnimation?: boolean;
  hasCheckIcon?: boolean;
  repeatBackgroundImage?: string;
  taskType?: string;
  isDownloadDialog?: boolean;
  downloadDialogMode?: DownloadDialogMode;
  taskId: string | undefined;
  isOpenSupportAccordion: boolean;
  targetModeEn: string | undefined;
  handleCloseDownloadDialog: () => void;
  refetchTasks: UseQueryResult['refetch'];
  swiperSwitchKey: SwiperSwitchKey;
  setSwiperSwitchKey: React.Dispatch<React.SetStateAction<SwiperSwitchKey>>;
};
// MEMO: 元々GlobbalViewerだったものを分離してサポート機能を追加にしたもの。子コンポーネントがかなりGlobalViewerと重複しているので、可能であれば共通化する。
// TODO: labelだけで仕様に追いつけなくなっているのでpropsから消してGlovalViewerと切り離す
// TODO: DownloadDialogのディレクトリに移動する
export const DownloadDialogViewer = memo(
  ({
    originalImages,
    imageResults,
    label,
    hasAnimation = false,
    hasCheckIcon = false,
    repeatBackgroundImage,
    taskType,
    isDownloadDialog,
    downloadDialogMode,
    taskId,
    isOpenSupportAccordion,
    targetModeEn,
    handleCloseDownloadDialog,
    refetchTasks,
    swiperSwitchKey,
    setSwiperSwitchKey,
  }: Props) => {
    const [generatedSwiperRef, setGeneratedSwiperRef] = useState<SwiperType | null>(null);
    const [originalSwiperRef, setOriginalSwiperRef] = useState<SwiperType | null>(null);
    const [swiperIndex, setSwiperIndex] = useState(0);
    const variant = useMemo(() => {
      return downloadDialogMode === 'selectSupportImage' || downloadDialogMode === 'supportForm' ? 'teal' : 'primary'
    }, [downloadDialogMode]);

    const resultImageUrls = useMemo(() => {
      return imageResults.map((imageResult) => imageResult.url);
    }, [
      imageResults,
    ])
    const originalImageUrls = useMemo(() => {
      return originalImages.map((originalImage) => originalImage.inputImage.originalImageUrl);
    }, [
      originalImages,
    ])

    return (
      <StyledGlobalViewerContainer>
        <AnimatePresence initial={hasAnimation}>
          <StyledSwiperMotionDiv
            key="viewer-left"
            initial={{ x: hasAnimation ? 100 : 0, opacity: 0.5 }}
            animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
          >
            <div style={{
              marginBottom: '8px',
            }}>
              <SwiperSwitch
                swiperSwitchKey={swiperSwitchKey}
                setSwiperSwitchKey={setSwiperSwitchKey}
                variant={variant}
              />
            </div>
            <div
              style={{
                display: swiperSwitchKey === 'generated' ? 'block' : 'none',
              }}
            >
              <Swiper
                imageUrls={resultImageUrls}
                swiperRef={generatedSwiperRef}
                swiperRef2={originalSwiperRef}
                setSwiperRef={setGeneratedSwiperRef}
                setSwiperRef2={setOriginalSwiperRef}
                swiperIndex={swiperIndex}
                setSwiperIndex={setSwiperIndex}
                repeatBackgroundImage={repeatBackgroundImage}
                taskType={taskType}
                downloadDialogMode={downloadDialogMode}
                variant={variant}
                hasCheckIcon={true}
              />
            </div>
            <div
              style={{
                display: swiperSwitchKey === 'original' ? 'block' : 'none',
              }}
            >
              <Swiper
                imageUrls={originalImageUrls}
                swiperRef={originalSwiperRef}
                swiperRef2={generatedSwiperRef}
                setSwiperRef={setOriginalSwiperRef}
                setSwiperRef2={setGeneratedSwiperRef}
                swiperIndex={swiperIndex}
                setSwiperIndex={setSwiperIndex}
                repeatBackgroundImage={repeatBackgroundImage}
                taskType={taskType}
                downloadDialogMode={downloadDialogMode}
                variant={variant}
                hasCheckIcon={false}
              />
            </div>
          </StyledSwiperMotionDiv>
          <StyledSwiperThumbsMotionDiv
            key="viewer-right"
            initial={{ x: hasAnimation ? -100 : 0, opacity: 0.5 }}
            animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
          >
            <SwiperThumbs
              imageResults={imageResults}
              label={label}
              hasCheckIcon={hasCheckIcon}
              thumbnailBoxWidth={420}
              swiperRef={generatedSwiperRef}
              swiperRef2={originalSwiperRef}
              swiperIndex={swiperIndex}
              setSwiperIndex={setSwiperIndex}
              repeatBackgroundImage={repeatBackgroundImage}
              variant={variant}
              downloadDialogMode={downloadDialogMode}
              taskId={taskId}
              isOpenSupportAccordion={isOpenSupportAccordion}
              targetModeEn={targetModeEn}
              handleCloseDownloadDialog={handleCloseDownloadDialog}
              refetchTasks={refetchTasks}
              swiperSwitchKey={swiperSwitchKey}
            />
          </StyledSwiperThumbsMotionDiv>
        </AnimatePresence>
      </StyledGlobalViewerContainer>
    );
  },
);
