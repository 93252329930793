import { StyledFlexAlignEndGap8, StyledFont16 } from '@/components/styled';
import { StyledNumberPlusMinusButton } from '@/components/styled/button';
import { memo } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { StyledGenCountBox } from './styled';

type Props = {
  generationCount: number;
  onClick: (order: number) => void;
  max?: number;
  min?: number;
  isDisabled?: boolean;
};

/**
 * JSDoc
 * @see 数値ボタン
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const NumberButton = memo(
  ({
    generationCount,
    onClick: handleClick,
    max = 4,
    min = 1,
    isDisabled = false,
  }: Props): JSX.Element => {
    return (
      <StyledFlexAlignEndGap8>
        <StyledNumberPlusMinusButton
          data-testid="minus-button"
          onClick={() => {
            handleClick(-1);
          }}
          disabled={isDisabled || generationCount === min}
        >
          <MdRemove data-testid="minus-icon" />
        </StyledNumberPlusMinusButton>
        <StyledGenCountBox>
          <StyledFont16>{generationCount}</StyledFont16>
        </StyledGenCountBox>

        <StyledNumberPlusMinusButton
          data-testid="plus-button"
          onClick={() => {
            handleClick(1);
          }}
          disabled={isDisabled || generationCount === max}
        >
          <MdAdd data-testid="plus-icon" />
        </StyledNumberPlusMinusButton>
      </StyledFlexAlignEndGap8>
    );
  },
);
