import { CircularProgress } from '@mui/material';
import React from 'react';
import { StyledLoadingIconBox } from './styled';

export type StaticButtonProps = {
  className?: string;
  children?: React.ReactNode;
  onClick?: (args?: unknown) => void;
  disabled?: boolean;
  isLoading?: boolean;
  'data-testid'?: string;
};
export const StaticButton = ({
  className,
  children,
  onClick,
  disabled,
  isLoading,
  'data-testid': dataTestId,
}: StaticButtonProps) => {
  return (
    <button
      type="button"
      className={className}
      onClick={onClick ? (!disabled ? () => onClick() : undefined) : undefined}
      style={
        disabled
          ? {
              backgroundColor: '#ddd',
              border: 0,
              color: '#999',
              cursor: 'default',
              boxShadow: 'none',
            }
          : undefined
      }
      data-testid={dataTestId}
    >
      {isLoading && (
        <StyledLoadingIconBox>
          <CircularProgress size={20} />
        </StyledLoadingIconBox>
      )}
      {children}
      {isLoading && <StyledLoadingIconBox />}
    </button>
  );
};
