import { TasksParam } from '@/api/params';
import { Box } from '@/components/atoms/Box';
import { Calendar } from '@/components/molecules/Calendar';
import { CalendarInput } from '@/components/molecules/CalendarInput';
import { Popper } from '@/components/molecules/Popper';
import {
  StyledBoxExpanded,
  StyledBoxWidth20,
  StyledFlexCenter,
  StyledFlexEnd,
  StyledFlexGap10,
  StyledFlexStart,
  StyledFont12Deep,
  StyledFontDeepNowrap,
  StyledFontNowrap,
} from '@/components/styled';
import { Switch } from '@mui/material';
import { memo } from 'react';
import { useGalleryCalendar } from './hooks/useGalleryCalendar';
import {
  StyledAdjustWrapper,
  StyledCalendarBetweenBox,
  StyledCalendarTitleBox,
  StyledSearchContainer,
} from './styled';

import {
  StyledCalendarClearBorderButton,
  StyledCalendarSearchButton,
} from '@/components/styled/button';

type Props = {
  updateTasksParamObject: (object: Partial<TasksParam>) => void;
  isToggledUserId: boolean;
  handleToggleUserId: () => void;
};

export const Search = memo(
  ({
    updateTasksParamObject,
    isToggledUserId,
    handleToggleUserId,
  }: Props): JSX.Element => {
    const {
      startDateString,
      setStartDateString,
      endDateString,
      setEndDateString,
      isStartDateError,
      setIsStartDateError,
      isEndDateError,
      setIsEndDateError,
      targetDate,
      handleOpenCalendar,
      handleClear,
      handleSubmit,
      anchorElement,
      isOpenPopper,
      handleClosePopper,
      isCalendarEnable,
    } = useGalleryCalendar({ updateTasksParamObject });

    return (
      <>
        <StyledSearchContainer>
          <StyledAdjustWrapper>
            <StyledCalendarTitleBox>
              <StyledFont12Deep>期間指定絞り込み</StyledFont12Deep>
            </StyledCalendarTitleBox>
            <StyledFlexStart>
              <Box>
                <CalendarInput
                  handleOpenCalendar={(e) => {
                    handleOpenCalendar(e, 'startDate');
                  }}
                  dateString={startDateString}
                  setDateString={setStartDateString}
                  isDateError={isStartDateError}
                  setIsDateError={setIsStartDateError}
                  isCalendarEnable={isCalendarEnable}
                />
              </Box>
              <StyledCalendarBetweenBox>
                <StyledFontNowrap>から</StyledFontNowrap>
              </StyledCalendarBetweenBox>
              <StyledBoxWidth20 />
              <Box>
                <CalendarInput
                  handleOpenCalendar={(e) => {
                    handleOpenCalendar(e, 'endDate');
                  }}
                  dateString={endDateString}
                  setDateString={setEndDateString}
                  isDateError={isEndDateError}
                  setIsDateError={setIsEndDateError}
                  isCalendarEnable={isCalendarEnable}
                />
              </Box>
              <StyledBoxWidth20 />
              <StyledFlexGap10>
                <StyledCalendarSearchButton
                  onClick={handleSubmit}
                  disabled={!startDateString && !endDateString}
                >
                  絞り込み
                </StyledCalendarSearchButton>
                <StyledCalendarClearBorderButton
                  onClick={handleClear}
                  disabled={!isCalendarEnable}
                >
                  クリア
                </StyledCalendarClearBorderButton>
              </StyledFlexGap10>
            </StyledFlexStart>
            <StyledFlexEnd>
              <StyledFontDeepNowrap>自分のタスクだけ表示</StyledFontDeepNowrap>
              <Switch checked={isToggledUserId} onChange={handleToggleUserId} />
            </StyledFlexEnd>
          </StyledAdjustWrapper>
        </StyledSearchContainer>
        { isOpenPopper &&
        <Popper
          onClose={handleClosePopper}
          anchorElement={anchorElement as unknown as HTMLElement}
          placement="bottom"
          placementY={0}
          hasClose={false}
          hasTitle={false}
        >
          <StyledFlexCenter>
            <StyledBoxExpanded>
              {targetDate === 'startDate' && (
                <Calendar
                  date={startDateString}
                  setDate={setStartDateString}
                  isError={isStartDateError}
                  isCalendarEnable={isCalendarEnable}
                />
              )}
              {targetDate === 'endDate' && (
                <Calendar
                  date={endDateString}
                  setDate={setEndDateString}
                  isError={isEndDateError}
                  isCalendarEnable={isCalendarEnable}
                />
              )}
            </StyledBoxExpanded>
          </StyledFlexCenter>
        </Popper>
        }
      </>
    );
  },
);
