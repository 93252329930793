import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import { StyledFlexAlignStart } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { GlobalViewer, GlobalViewerMode } from '@/features/components/organisms/GlobalViewer';
import { KeepMask } from '@/features/components/organisms/KeepMask';
import { StyledPreview } from '@/features/components/styled/preview';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useEffect, useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { SwiperSlide } from 'swiper/react';
import { CircledTargetContent } from './CircledTargetContent';
import { Label } from './Label/Label';
import { accordionToggleButtonStyle, normalTextStyle, tealTextStyle } from './Preview.css';
import { useSupport } from './hooks/useSupport';
import { StyledGlobalViewerPreviewBox, StyledPreviewContainer } from './styled';

type Props = {
  repeatBackgroundImage: string;
  globalViewerMode: GlobalViewerMode;
  setGlobalViewerMode: React.Dispatch<React.SetStateAction<GlobalViewerMode>>;
};
export const Preview = ({
  repeatBackgroundImage,
  globalViewerMode,
  setGlobalViewerMode,
}: Props) => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  const imageResults = useMemo(() => {
    return featureData?.single?.genResult.generatedImageResults || []
  }, [
    featureData,
  ])

  const {
    handleClickSupportStartButton,
    handleClickSupportCancelButton,
    handleClickApplySupportWithSelectImageButton
  } = useSupport({
    setGlobalViewerMode
  });

  const {
    checkArray,
    setCheckArray,
    checkedCount,
  } = useCheckArray();

  const filteredImages = useMemo(() => {
    switch (globalViewerMode) {
      case 'viewPreview':
        return imageResults
      case 'selectDownloadImage':
        return imageResults
      case 'selectSupportImage':
        return imageResults
      case 'supportForm':
        return imageResults.filter((_, index) => checkArray[index])
      default:
        throw new Error("Invalid GlobalViewerMode");
    }
  }, [
    imageResults,
    checkArray,
    globalViewerMode,
  ]);

  const isDisplayAcoordion = useMemo(() => {
    return globalViewerMode === 'supportForm' && checkedCount >= 5
  }, [
    globalViewerMode,
    checkedCount,
  ])

  const isDisabledKeepMask = useMemo(() => {
    return globalViewerMode === 'supportForm' || globalViewerMode === 'selectSupportImage'
  }, [
    globalViewerMode,
  ])

  const [isOpenSupportAccordion, setIsOpenSupportAccordion] = useState(false)

  const label = useMemo(() => {
    return (
      <>
        <Label
          globalViewerMode={globalViewerMode}
          handleClickSupportStartButton={handleClickSupportStartButton}
          handleClickSupportCancelButton={handleClickSupportCancelButton}
        />
        <StyledGlobalViewerPreviewBox>
          プレビュー中
        </StyledGlobalViewerPreviewBox>
        {globalViewerMode === 'supportForm' && (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '17px',
          }}>
            <span className={normalTextStyle}>
              選択した画像
              {checkedCount ? `（${checkedCount}）` : ''}
            </span>
            { isDisplayAcoordion &&
              <button
                type="button"
                className={accordionToggleButtonStyle}
                onClick={() => setIsOpenSupportAccordion(!isOpenSupportAccordion)}
              >
                { isOpenSupportAccordion ?
                <>
                  <span className={tealTextStyle}>
                    表示を減らす
                  </span>
                  <MdExpandLess
                    size={16}
                    color="var(--color-teal-400)"
                  />
                </>
                :
                <>
                  <span className={tealTextStyle}>
                    すべての画像を表示
                  </span>
                  <MdExpandMore
                    size={16}
                    color="var(--color-teal-400)"
                  />
                </>
                }
              </button>
            }
          </div>
        )}
      </>
    )
  }, [
    globalViewerMode,
    handleClickSupportCancelButton,
    handleClickSupportStartButton,
    checkedCount,
    isDisplayAcoordion,
    isOpenSupportAccordion,
  ])

  useEffect(() => {
    setCheckArray(imageResults.map(() => true));
  }, [imageResults, setCheckArray]);

  const hasCheckIcon = useMemo(() => {
    return globalViewerMode === 'selectSupportImage'
  }, [
    globalViewerMode,
  ])

  return (
    <>
      <StyledFlexAlignStart>
        <StyledPreviewContainer>
          {!featureData?.single?.genStatus?.isGenerated && (
            <>
              <CircledTargetContent />
              <StyledPreview swiperIndex={0} maxIndex={1} width={660} height={600}>
                <SwiperSlide>
                  {featureData?.mainImage.combinedBase64 && (
                    <ImageWithLoader
                      src={featureData?.mainImage.combinedBase64}
                      fill
                      style={{ objectFit: 'contain' }}
                      alt=""
                      loaderHeight={24}
                      loaderWidth={24}
                      loaderMode="spinner"
                    />
                  )}
                </SwiperSlide>
              </StyledPreview>
            </>
          )}
          {featureData?.single?.genStatus?.isGenerated &&
            filteredImages.length > 0 && (
              <GlobalViewer
                imageResults={filteredImages}
                repeatBackgroundImage={repeatBackgroundImage}
                label={label}
                hasAnimation
                hasCheckIcon={hasCheckIcon}
                globalViewerMode={globalViewerMode}
                taskId={featureData?.single?.genResult.taskId}
                isOpenSupportAccordion={isOpenSupportAccordion}
                checkedCount={checkedCount}
                handleClickSupportCancelButton={handleClickSupportCancelButton}
                handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
              />
            )}
        </StyledPreviewContainer>
        {activeFeatureName === 'item' && (
          <>
            <KeepMask
              isDisabled={isDisabledKeepMask}
            />
          </>
        )}
      </StyledFlexAlignStart>
    </>
  );
};
