import { Task } from '@/api/schemas';
import houndstooth from '@/assets/any/houndstooth.png';
import { Box } from '@/components/atoms/Box';
import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import {
  StyledBoxPaddingTop100,
  StyledFlex,
  StyledGrid,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useParamsContext } from '@/contexts/ParamsContext';
import { DownloadDialogDownloader } from '@/features/components/organisms/DownloadDialogDownloader/DownloadDialogDownloader';
import { SwiperSwitchKey } from '@/features/components/organisms/DownloadDialogViewer/SwiperSwitch/hooks/useSwiperSwitch';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesModalDownloadLayout } from '@/features/components/templates/Layout/FeaturesModalDownloadLayout';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useDownload } from '@/hooks/global/useDownload';
import { Divider } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { memo, useCallback, useMemo } from 'react';
import type { DownloadDialogMode } from '../DownloadDialog';
import { ReEditButton } from '../ReEditButton';
import { SharedDownloadButton } from '../SharedDownloadButton';
import { SharedHeaderContent } from '../SharedHeaderContent';
import { SupportApplyButton } from '../SupportButton/SupportButton';
import { StyledDialogContainer, StyledDialogWidthBox } from '../styled';

type Props = {
  taskApiResponse: Task;
  handleOpenPopper: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
  downloadDialogMode: DownloadDialogMode;
  handleClickSupportStartButton: () => void;
  handleClickSupportCancelButton: () => void;
  handleClickApplySupportWithSelectImageButton: () => void;
  targetModeEn: string;
  handleCloseDownloadDialog: () => void;
  refetchTasks: UseQueryResult['refetch'];
  swiperSwitchKey: SwiperSwitchKey;
  setSwiperSwitchKey: React.Dispatch<React.SetStateAction<SwiperSwitchKey>>;
  handleResetSwiperSwitchKey: () => void;
};

export const ColorTemperatureContent = memo(
  ({
    taskApiResponse,
    handleOpenPopper,
    checkedCount,
    downloadDialogMode,
    handleClickSupportStartButton,
    handleClickSupportCancelButton,
    handleClickApplySupportWithSelectImageButton,
    targetModeEn,
    handleCloseDownloadDialog,
    refetchTasks,
    swiperSwitchKey,
    setSwiperSwitchKey,
    handleResetSwiperSwitchKey,
  }: Props): JSX.Element => {
    const { setFiles } = useDownload({
      taskApiResponse,
    });

    const { activateTargetStep } = useFeaturesContext({})
    const { activateTargetFeatureWithParams } = useParamsContext()
    const { checkArray } = useCheckArray();

    const moveToReEditColorTemperature = useCallback(() => {
      if (!taskApiResponse?.id) return

      const indexes: number[] = [];
      checkArray.forEach((isChecked, index) => {
        if (isChecked) indexes.push(index)
      })
      activateTargetStep('reEditContainer');
      activateTargetFeatureWithParams('reEditColorTemperature', {
        id: taskApiResponse.id,
        selectedImageIndexes: indexes,
      })
    },[
      activateTargetStep,
      activateTargetFeatureWithParams,
      taskApiResponse?.id,
      checkArray,
    ]);

    const hasCheckIcon = useMemo(() => {
      return swiperSwitchKey === 'generated' && downloadDialogMode !== 'supportForm'
    }, [
      downloadDialogMode,
      swiperSwitchKey,
    ])

    return (
      <>
        <StyledDialogContainer style={{ width: 1200 }}>
          <FeaturesModalDownloadLayout>
            <StyledDialogWidthBox style={{ width: '1150px' }}>
              <SharedHeaderContent taskApiResponse={taskApiResponse} />
              <Divider />
            </StyledDialogWidthBox>
            <StyledBoxPaddingTop100>
              <StyledFlex
                style={{
                  position: 'relative',
                  alignItems: 'flex-start',
                  width: '1100px',
                  gap: '20px',
                }}
              >
                {taskApiResponse.refImage?.inputImage.originalImageUrl && (
                  <StyledGrid style={{ gap: '16px' }}>
                    <Box
                      style={{
                        position: 'relative',
                        width: 240,
                        height: 240,
                        minWidth: 240,
                      }}
                    >
                      <ImageWithLoader
                        src={`${taskApiResponse.refImage.inputImage.originalImageUrl}&d=240x240`}
                        fill
                        quality={90}
                        style={{
                          objectFit: 'contain',
                          objectPosition: 'center',
                          borderRadius: '8px',
                          outline: '1px solid #ccc',
                        }}
                        alt=""
                        loaderHeight={24}
                        loaderWidth={24}
                        loaderMode="spinner"
                      />
                    </Box>
                    <Box
                      style={{
                        fontSize: 14,
                        color: '#666',
                        textAlign: 'center',
                      }}
                    >
                      参照画像
                    </Box>
                  </StyledGrid>
                )}
                <DownloadDialogDownloader
                  isGenerated
                  originalImages={taskApiResponse?.originalImages || []}
                  imageResults={taskApiResponse?.result.resultImages || []}
                  setFiles={setFiles}
                  repeatBackgroundImage={
                    taskApiResponse.parameters?.noBackground
                      ? houndstooth.src
                      : ''
                  }
                  hasCheckIcon={hasCheckIcon}
                  taskType={taskApiResponse.taskType}
                  downloadDialogMode={downloadDialogMode}
                  handleClickSupportStartButton={handleClickSupportStartButton}
                  handleClickSupportCancelButton={handleClickSupportCancelButton}
                  taskId={taskApiResponse?.id}
                  targetModeEn={targetModeEn}
                  handleCloseDownloadDialog={handleCloseDownloadDialog}
                  refetchTasks={refetchTasks}
                  swiperSwitchKey={swiperSwitchKey}
                  setSwiperSwitchKey={setSwiperSwitchKey}
                  handleResetSwiperSwitchKey={handleResetSwiperSwitchKey}
                />
              </StyledFlex>
            </StyledBoxPaddingTop100>
            <StyledFunctionBox>
              <StyledBoxPaddingTop100>
                { downloadDialogMode === 'selectDownloadImage' &&
                  <>
                    <SharedDownloadButton
                      bottom={48}
                      handleOpenPopper={handleOpenPopper}
                      checkedCount={checkedCount}
                      swiperSwitchKey={swiperSwitchKey}
                    />
                    <ReEditButton
                      handleClick={moveToReEditColorTemperature}
                      checkedCount={checkedCount}
                      swiperSwitchKey={swiperSwitchKey}
                    />
                  </>
                }
                { downloadDialogMode === 'selectSupportImage' &&
                  <SupportApplyButton
                    handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
                    checkedCount={checkedCount}
                    swiperSwitchKey={swiperSwitchKey}
                  />
                }
              </StyledBoxPaddingTop100>
            </StyledFunctionBox>
          </FeaturesModalDownloadLayout>
        </StyledDialogContainer>
      </>
    );
  },
);
