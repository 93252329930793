/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@/components/atoms/Box';
import { BigChecked, BigUnChecked } from '@/components/icons/others';
import { StyledFlexCenterExpanded } from '@/components/styled';
import { DownloadDialogMode } from '@/features/components/modals/DownloadDialog';
import {
  StyledPreview,
  StyledPreviewBox,
  StyledPreviewSlide
} from '@/features/components/styled/preview';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useGetResizedSize } from '@/hooks/utils/useGetResizedSize';
import { CircularProgress } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { memo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Thumbs } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper/types';
import { useSwiperState } from '../hooks/useSwiperState';
import { StyledSwiperContainer } from './styled';
import { bigCircleTealStyle } from './styled/vanilla.css';

type Props = {
  imageUrls: string[];
  swiperRef: SwiperType | null;
  swiperRef2: SwiperType | null;
  setSwiperRef: (swiper: SwiperType) => void;
  setSwiperRef2: (swiper: SwiperType) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
  repeatBackgroundImage?: string;
  taskType?: string;
  downloadDialogMode: DownloadDialogMode | undefined;
  variant?: 'primary' | 'teal';
  hasCheckIcon: boolean;
};

// ダウンロードダイアログの画像スワイパー
// 生成前画像スワイパーと元画像スワイパーがサムネイルスワイパーに連携している
export const Swiper = memo(
  ({
    imageUrls,
    swiperRef,
    setSwiperRef,
    swiperRef2,
    setSwiperRef2,
    swiperIndex,
    setSwiperIndex,
    repeatBackgroundImage,
    taskType,
    downloadDialogMode,
    variant,
    hasCheckIcon,
  }: Props) => {
    const {
      handleChangeSlide,
    } = useSwiperState({
      swiperRef,
      swiperRef2,
      swiperIndex,
      setSwiperIndex,
    });
    const { imageWidth, imageHeight } = useGetResizedSize({
      imageUrl: imageUrls[0],
      maxWidth: 512,
      maxHeight: 551,
    });

    const { findDownloaded, checkIndex, checkArray } = useCheckArray();
    const [isImageLoading, setIsImageLoading] = useState(true);

    return (
      <StyledSwiperContainer>
        <Box style={{ width: 512 }}>
          <StyledPreviewBox
            height={551}
          >
            <StyledPreview
              onSwiper={(swiper) => {
                setSwiperRef(swiper);
              }}
              onSlideChangeTransitionEnd={(swiper) => {
                handleChangeSlide(swiper.realIndex);
              }}
              grabCursor
              centeredSlides
              slidesPerView="auto"
              spaceBetween={30}
              navigation
              modules={[Navigation, Thumbs]}
              swiperIndex={swiperIndex}
              maxIndex={imageUrls.length - 1}
              className="mySwiper"
              width={512}
              height={551}
            >
              <>
                {imageUrls.map((imageUrl: string, i: number) => (
                  <StyledPreviewSlide
                    key={`image-${imageUrl}-${i}`.toString()}
                    width={512}
                    height={551}
                  >
                    {(hasCheckIcon && downloadDialogMode !== 'supportForm') && (
                      <Box
                        style={{
                          position: 'absolute',
                          right: 5,
                          bottom: 5,
                          zIndex: 20,
                        }}
                        onClick={() => {
                          checkIndex(i);
                        }}
                      >
                        {checkArray[i] && (
                          <Box style={{ cursor: 'pointer' }}>
                            <BigChecked
                              className={variant === 'teal' ? bigCircleTealStyle : ''}
                            />
                          </Box>
                        )}
                        {!checkArray[i] && (
                          <Box
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              checkIndex(i);
                            }}
                          >
                            <BigUnChecked />
                          </Box>
                        )}
                      </Box>
                    )}
                    {findDownloaded(i) && (
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                          position: 'absolute',
                          zIndex: 10,
                          left: 0,
                          top: 0,
                          backgroundColor: hexToRgba('#fff', '0.5'),
                          color: '#000',
                          fontSize: 12,
                          fontWeight: 512,
                          padding: '4px',
                        }}
                      >
                        <Box
                          style={{
                            width: 10,
                            height: 10,
                            backgroundColor: '#545454',
                            border: '1px solid #fff',
                            borderRadius: '50%',
                          }}
                        />
                        ダウンロード済み
                      </Box>
                    )}
                    <StyledFlexCenterExpanded>
                      { isImageLoading &&
                        <CircularProgress data-testid="spinner" sx={{ width: 32, height: 32 }} />
                      }
                      <img
                        src={imageUrl}
                        width={imageWidth}
                        height={imageHeight}
                        style={{
                          objectFit: 'contain',
                          backgroundImage:
                            repeatBackgroundImage && imageWidth && imageHeight
                              ? `url(${repeatBackgroundImage})`
                              : '',
                        }}
                        alt=""
                        onLoad={() => {setIsImageLoading(false)}}
                      />
                    </StyledFlexCenterExpanded>
                  </StyledPreviewSlide>
                ))}
              </>
            </StyledPreview>
          </StyledPreviewBox>
        </Box>
      </StyledSwiperContainer>
    );
  },
);
