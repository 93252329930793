import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { DownloadRadioButton } from '@/components/molecules/DownloadRadioButton/DownloadRadioButton';
import {
  StyledFlexGap10,
  StyledFlexGap5,
  StyledGridGap10,
} from '@/components/styled';
import { StyledFunctionPadding } from '@/features/components/styled/function';
import { CircularProgress } from '@mui/material';
import { memo, useCallback } from 'react';

type Props = {
  format: string;
  setFormat: (format: string) => void;
  isDialog?: boolean;
  onClick: () => void;
  onClose?: () => void;
  isDownloading: boolean;
  isDownloaded: boolean;
  isNoBackground: boolean;
  hasPsd?: boolean;
};

export const DownloadFormatMenu = memo(
  ({
    format,
    setFormat,
    isDialog,
    onClick,
    onClose,
    isDownloading,
    isDownloaded,
    isNoBackground,
    hasPsd = false,
  }: Props): JSX.Element => {
    const handleClickFormat = useCallback(
      (target: string) => {
        if (!isDialog) {
          // updateFeatureDataSingle('param', { format: target });
        }
        setFormat(target);
      },
      [isDialog, setFormat],
    );

    return (
      <>
        <StyledFunctionPadding sx={{ width: 324, marginTop: '-20px' }}>
          <StyledGridGap10>
            <DownloadRadioButton
              target="png"
              radioValue={format || 'png'}
              onClick={() => {
                handleClickFormat('png');
              }}
              width={284}
              label="PNG"
              description="高画質"
              isActive={format === 'png'}
            />
            {!isNoBackground && (
              <DownloadRadioButton
                target="jpg"
                radioValue={format || 'png'}
                onClick={() => {
                  handleClickFormat('jpg');
                }}
                width={284}
                label="JPG"
                description="軽量・高圧縮"
                isActive={format === 'jpg'}
              />
            )}
            <DownloadRadioButton
              target="webp"
              radioValue={format || 'png'}
              onClick={() => {
                handleClickFormat('webp');
              }}
              width={284}
              label="WebP"
              description="Web最適化"
              isActive={format === 'webp' || false}
            />
            {hasPsd && !isNoBackground && (
              <DownloadRadioButton
                target="psd"
                radioValue={format || 'png'}
                onClick={() => {
                  handleClickFormat('psd');
                }}
                width={284}
                label="PSD"
                description="編集可能"
                isActive={format === 'psd'}
              />
            )}
            <div>
              {(hasPsd && !isNoBackground) &&
                <div>
                  <span style={{ display: 'inline-block', fontSize: 12, lineHeight: '160%', letterSpacing: '0.4px', marginRight: '4px'}}>
                    ※
                  </span>
                  <span style={{ display: 'inline-block', fontSize: 12, lineHeight: '160%', letterSpacing: '0.4px'}}>
                    PSDダウンロードは最大5枚までとなります
                  </span>
                </div>
              }
              <div>
                <span style={{ display: 'inline-block', fontSize: 12, lineHeight: '160%', letterSpacing: '0.4px', marginRight: '4px'}}>
                  ※
                </span>
                <span style={{ display: 'inline-block', fontSize: 12, lineHeight: '160%', letterSpacing: '0.4px'}}>
                  ダウンロードに時間が掛かる場合があります
                </span>
              </div>
            </div>
          </StyledGridGap10>
          <Box style={{ height: 24 }} />
          <StyledFlexGap10>
            <Button
              width={104}
              height={36}
              mode="border"
              style={{ fontSize: 14 }}
              onClick={onClose}
            >
              キャンセル
            </Button>
            <Button
              width={200}
              height={36}
              style={{ fontSize: 14 }}
              onClick={onClick}
            >
              <StyledFlexGap5>
                {isDownloading && !isDownloaded ? (
                  <CircularProgress style={{ width: 20, height: 20 }} />
                ) : (
                  <Box style={{ width: 20, height: 20 }} />
                )}
                一括ダウンロード
                <Box style={{ width: 20, height: 20 }} />
              </StyledFlexGap5>
            </Button>
          </StyledFlexGap10>
        </StyledFunctionPadding>
      </>
    );
  },
);
