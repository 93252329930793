import { Task } from '@/api/schemas';
import houndstooth from '@/assets/any/houndstooth.png';
import { StyledBoxPaddingTop100, StyledFlex } from '@/components/styled';
import { DownloadDialogDownloader } from '@/features/components/organisms/DownloadDialogDownloader/DownloadDialogDownloader';
import { SwiperSwitchKey } from '@/features/components/organisms/DownloadDialogViewer/SwiperSwitch/hooks/useSwiperSwitch';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesModalDownloadLayout } from '@/features/components/templates/Layout/FeaturesModalDownloadLayout';
import { useDownload } from '@/hooks/global/useDownload';
import { Divider } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { memo, useMemo } from 'react';
import type { DownloadDialogMode } from '../DownloadDialog';
import { SharedDownloadButton } from '../SharedDownloadButton';
import { SharedHeaderContent } from '../SharedHeaderContent';
import { SupportApplyButton } from '../SupportButton/SupportButton';
import { StyledDialogContainer, StyledDialogWidthBox } from '../styled';

type Props = {
  taskApiResponse: Task;
  handleOpenPopper: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
  downloadDialogMode: DownloadDialogMode;
  handleClickSupportStartButton: () => void;
  handleClickSupportCancelButton: () => void;
  handleClickApplySupportWithSelectImageButton: () => void;
  targetModeEn: string;
  handleCloseDownloadDialog: () => void;
  refetchTasks: UseQueryResult['refetch'];
  swiperSwitchKey: SwiperSwitchKey;
  setSwiperSwitchKey: React.Dispatch<React.SetStateAction<SwiperSwitchKey>>;
  handleResetSwiperSwitchKey: () => void;
};

export const BatchDownloadContent = memo(
  ({
    taskApiResponse,
    handleOpenPopper,
    checkedCount,
    downloadDialogMode,
    handleClickSupportStartButton,
    handleClickSupportCancelButton,
    handleClickApplySupportWithSelectImageButton,
    targetModeEn,
    handleCloseDownloadDialog,
    refetchTasks,
    swiperSwitchKey,
    setSwiperSwitchKey,
    handleResetSwiperSwitchKey,
  }: Props): JSX.Element => {
    const { setFiles } = useDownload({
      taskApiResponse,
    });

    const hasCheckIcon = useMemo(() => {
      return swiperSwitchKey === 'generated' && downloadDialogMode !== 'supportForm'
    }, [
      downloadDialogMode,
      swiperSwitchKey,
    ])

    return (
      <>
        <StyledDialogContainer style={{ width: 940 }}>
          <FeaturesModalDownloadLayout>
            <StyledDialogWidthBox style={{ width: '890px' }}>
              <SharedHeaderContent taskApiResponse={taskApiResponse} />
              <Divider />
            </StyledDialogWidthBox>
            <StyledBoxPaddingTop100>
              <StyledFlex
                style={{
                  position: 'relative',
                  alignItems: 'flex-start',
                  width: '890px',
                  gap: '20px',
                }}
              >
                <DownloadDialogDownloader
                  isGenerated
                  originalImages={taskApiResponse?.originalImages || []}
                  imageResults={taskApiResponse?.result.resultImages || []}
                  setFiles={setFiles}
                  repeatBackgroundImage={
                    taskApiResponse.parameters?.noBackground
                      ? houndstooth.src
                      : ''
                  }
                  hasCheckIcon={hasCheckIcon}
                  taskType={taskApiResponse.taskType}
                  downloadDialogMode={downloadDialogMode}
                  handleClickSupportStartButton={handleClickSupportStartButton}
                  handleClickSupportCancelButton={handleClickSupportCancelButton}
                  taskId={taskApiResponse.id}
                  targetModeEn={targetModeEn}
                  handleCloseDownloadDialog={handleCloseDownloadDialog}
                  refetchTasks={refetchTasks}
                  swiperSwitchKey={swiperSwitchKey}
                  setSwiperSwitchKey={setSwiperSwitchKey}
                  handleResetSwiperSwitchKey={handleResetSwiperSwitchKey}
                />
              </StyledFlex>
            </StyledBoxPaddingTop100>
            <StyledFunctionBox>
              <StyledBoxPaddingTop100>
                { downloadDialogMode === 'selectDownloadImage' &&
                  <SharedDownloadButton
                    handleOpenPopper={handleOpenPopper}
                    checkedCount={checkedCount}
                    swiperSwitchKey={swiperSwitchKey}
                  />
                }
                { downloadDialogMode === 'selectSupportImage' &&
                  <SupportApplyButton
                    handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
                    checkedCount={checkedCount}
                    swiperSwitchKey={swiperSwitchKey}
                  />
                }
              </StyledBoxPaddingTop100>
            </StyledFunctionBox>
          </FeaturesModalDownloadLayout>
        </StyledDialogContainer>
      </>
    );
  },
);
