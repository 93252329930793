import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { atom, useAtom } from 'jotai';

const errorAtom = atom<Error | null>(null);
const generatingStatusAtom = atom<string | null>(null);
const isErrorAtom = atom<boolean>(false);

// type ErrorState = Error | AxiosError | { message: string; details: unknown };

type Props = {
  error: unknown; // TODO: ErrorStateに置き換える
  status?: string | null;
  setIsLoading?: (isLoading: boolean) => void;
  setIsGenerated?: (isGenerated: boolean) => void;
};

/**
 * JSDoc
 * @see エラーを管理するカスタムフック
 * @see Sentryにエラーを送信する
 * @returns {Object}
 */
export const useError = () => {
  // function normalizeError(error: unknown): ErrorState {
  //   if (error instanceof AxiosError) {
  //     return error; // AxiosErrorはそのまま返す
  //   }

  //   if (error instanceof Error) {
  //     return error; // 通常のErrorオブジェクト
  //   }

  //   // それ以外の場合はフォールバックとしてメッセージだけを返す
  //   return {
  //     message: 'An unknown error occurred',
  //     details: error
  //   };
  // }

  const [error, setError] = useAtom(errorAtom);
  const [generatingStatus, setGeneratingStatus] = useAtom(generatingStatusAtom);

  const [isError, setIsError] = useAtom(isErrorAtom);

  const logger = ({ error: _error, setIsLoading, setIsGenerated }: Props) => {
    if (_error instanceof Error) {
      setError(_error as Error );
    } else if (_error instanceof AxiosError) {
      setError(_error as AxiosError)
    } else {
      // biome-ignore lint/suspicious/noExplicitAny: あとで修正
      setError(_error as any)
    }
    Sentry.captureException(error);

    if (setIsLoading) {
      setIsLoading(false);
    }
    if (setIsGenerated) {
      setIsGenerated(false);
    }
    setIsError(true);
  };

  const loggerFromGeneratingStatus = (status: string) => {
    console.log(status);
    if (status) setGeneratingStatus(status);
    Sentry.captureException(status);
    setIsError(true);
  };

  return {
    error,
    logger,
    isError,
    setIsError,
    generatingStatus,
    loggerFromGeneratingStatus,
  };
};
