import { Box } from '@/components/atoms/Box';
import {
  BackgroundIcon,
  ColorIcon,
  ColorTemperatureIcon,
  GalleryIcon,
  ItemIcon,
  WhiteIcon,
} from '@/components/icons';
import { StyledFlexEnd, StyledGridGap5 } from '@/components/styled';
import { StyledHomeBorderButton } from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import {
  StyledLargeIconBox,
  StyledLargeIconScale,
  StyledMediumIconBox
} from '@/features/components/styled/icon';
import { FeaturesSingleLayout } from '@/features/components/templates/Layout/FeaturesSingleLayout';
import { memo, useCallback, useState } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import { PiCaretRightLight } from 'react-icons/pi';
import {
  StyledCardBox,
  StyledCardBox90,
  StyledCardButtonFlex,
  StyledCardExplain,
  StyledCardTitle,
  StyledCardTitleWrapper,
  StyledCardUpperFlex,
  StyledDashboardCardBox,
  StyledDashboardContainer,
} from './styled';
import { lpLinkIconStyle, lpLinkSpanStyle, lpLinkStyle } from './styled/vanilla.css';

type LPLinkProps = {
  href: string;
}
const LPLink = ({
  href,
}: LPLinkProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={lpLinkStyle}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <MdPlayCircleFilled
        size={24}
        className={lpLinkIconStyle}
        fill={isHover ? 'var(--color-gray-main)' : 'var(--color-primary-deep)'}
        // fill="var(--color-primary-deep)"
      />
      <span className={lpLinkSpanStyle}>使い方動画</span>
    </a>
  );
}

export const Dashboard = memo((): JSX.Element => {
  const { activateTargetFeature } = useFeaturesContext({});
  const handleClick = useCallback(
    (feature: string) => {
      activateTargetFeature(feature);
    },
    [activateTargetFeature],
  );

  return (
    <FeaturesSingleLayout>
      <StyledDashboardContainer>
        <StyledDashboardCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <BackgroundIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5 style={{width: '100%'}}>
                <StyledCardTitleWrapper>
                  <StyledCardTitle>背景画像を変える</StyledCardTitle>
                  <LPLink href="https://lp.sugekae.com/sugekae-video-tutorial/?playlist=7f2c7b66&video=731ffb3" />
                </StyledCardTitleWrapper>
                <StyledCardExplain>
                  画像の背景を単色カラー／一般的な背景に挿げ替える
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('background');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('backgroundLora');
                }}
              >
                複数枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <WhiteIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5 style={{width: '100%'}}>
                <StyledCardTitleWrapper>
                  <StyledCardTitle>背景を白抜きに変える</StyledCardTitle>
                  <LPLink href="https://lp.sugekae.com/sugekae-video-tutorial/?playlist=7f2c7b66&video=3f2bf87" />
                </StyledCardTitleWrapper>
                <StyledCardExplain>
                  画像の背景を白抜き／透明に挿げ替える
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('white');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('multiWhite');
                }}
              >
                複数枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
        </StyledDashboardCardBox>
        <StyledDashboardCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <ColorIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5 style={{width: '100%'}}>
                <StyledCardTitleWrapper>
                  <StyledCardTitle>カラーを変える</StyledCardTitle>
                  <LPLink href="https://lp.sugekae.com/sugekae-video-tutorial/?playlist=7f2c7b66&video=3e6259f" />
                </StyledCardTitleWrapper>
                <StyledCardExplain>
                  選択したアイテムのカラーを変更する
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('color');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <ColorTemperatureIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5 style={{width: '100%'}}>
                <StyledCardTitleWrapper>
                  <StyledCardTitle>色温度を調整する</StyledCardTitle>
                  <LPLink href="https://lp.sugekae.com/sugekae-video-tutorial/?playlist=7f2c7b66&video=9da7086" />
                </StyledCardTitleWrapper>
                <StyledCardExplain>
                  選択したアイテムの色温度を調整する
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('colorTemperature');
                }}
              >
                複数枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
        </StyledDashboardCardBox>
        <StyledDashboardCardBox style={{ alignItems: 'flex-start' }}>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <ItemIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5 style={{width: '100%'}}>
                <StyledCardTitleWrapper>
                  <StyledCardTitle>アイテムを変える</StyledCardTitle>
                  <LPLink href="https://lp.sugekae.com/sugekae-video-tutorial/?playlist=7f2c7b66&video=905b483" />
                </StyledCardTitleWrapper>
                <StyledCardExplain>
                  選択したアイテムのデザインを変更する
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('item');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
          <StyledCardBox90
            onClick={() => {
              handleClick('gallery');
            }}
          >
            <StyledCardUpperFlex>
              <Box>
                <StyledMediumIconBox>
                  <GalleryIcon color="#424242" />
                </StyledMediumIconBox>
              </Box>
              <StyledGridGap5>
                <StyledCardTitle>生成ギャラリー</StyledCardTitle>
              </StyledGridGap5>
              <StyledFlexEnd>
                <PiCaretRightLight size={16} />
              </StyledFlexEnd>
            </StyledCardUpperFlex>
          </StyledCardBox90>
        </StyledDashboardCardBox>
      </StyledDashboardContainer>
    </FeaturesSingleLayout>
  );
});
