import { GlobalViewerMode } from '@/features/components/organisms/GlobalViewer';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useCallback } from 'react';

type Props = {
  setGlobalViewerMode: React.Dispatch<React.SetStateAction<GlobalViewerMode>>;
}
export const useSupport = ({
  setGlobalViewerMode,
}: Props) => {
  // MEMO: サポートボタンに関する処理（必要あれば別ファイルに切り出す）
  const { handleCheckAll, handleUnCheckAll } = useCheckArray();
  // サポートモード開始処理
  const handleClickSupportStartButton = useCallback(() => {
    handleUnCheckAll();
    setGlobalViewerMode('selectSupportImage')
  }, [
    handleUnCheckAll,
    setGlobalViewerMode,
  ])
  // サポートモードキャンセル処理
  const handleClickSupportCancelButton = useCallback(() => {
    setGlobalViewerMode('viewPreview')
    // 選択画像をダイアログの開いた状態にリセットする
    handleCheckAll();
  }, [
    handleCheckAll,
    setGlobalViewerMode,
  ])
  // 『選択した画像でサポートを申請』処理（実際には申請実行ではなく、フォームに遷移する処理）
  const handleClickApplySupportWithSelectImageButton = useCallback(() => {
    setGlobalViewerMode('supportForm')
  }, [
    setGlobalViewerMode,
  ])

  return {
    handleClickSupportStartButton,
    handleClickSupportCancelButton,
    handleClickApplySupportWithSelectImageButton
  };
};
