import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Dispatch, SetStateAction, memo } from 'react';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useCalendar } from './hooks/useCalendar';
import {
  StyledDateCell,
  StyledDateTitle,
  StyledDateTitleBox,
  StyledDayCell,
  StyledNextMonth,
  StyledPreviousMonth,
  StyledTable,
  StyledTableContainer,
} from './styled';

export type Props = {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  isError?: boolean;
  isCalendarEnable: boolean;
};

/**
 * JSDoc
 * @see カレンダーコンポーネント
 * @see 予約日を選択する際に使用する
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Calendar = memo(
  ({ date, setDate, isError, isCalendarEnable }: Props): JSX.Element => {
    const {
      daysOfWeek,
      calendarDays,
      currentYear,
      currentMonth,
      isToday,
      isDateSelected,
      isFutureDate,
      isCurrentMonthDate,
      handleDateClick,
      handleGoToPreviousMonth,
      handleGoToNextMonth,
    } = useCalendar({ date, setDate, isError, isCalendarEnable });

    return (
      <StyledTableContainer>
        <StyledTable>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={7}>
                  <StyledDateTitleBox>
                    <StyledPreviousMonth onClick={handleGoToPreviousMonth}>
                      <MdOutlineKeyboardArrowLeft size={20} />
                    </StyledPreviousMonth>
                    <StyledDateTitle>
                      {currentYear}年{currentMonth + 1}月
                    </StyledDateTitle>
                    <StyledNextMonth
                      onClick={() => {
                        !isFutureDate(calendarDays[calendarDays.length - 1]) &&
                          handleGoToNextMonth();
                      }}
                      isFuture={isFutureDate(
                        calendarDays[calendarDays.length - 1],
                      )}
                    >
                      <MdOutlineKeyboardArrowRight size={20} />
                    </StyledNextMonth>
                  </StyledDateTitleBox>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {daysOfWeek.map((day: string) => (
                  <StyledDayCell key={day}>{day}</StyledDayCell>
                ))}
              </TableRow>
              {Array.from(
                { length: Math.ceil(calendarDays.length / 7) },
                (_, week) => (
                  // biome-ignore lint/suspicious/noArrayIndexKey: あとで修正
                  <TableRow key={week}>
                    {calendarDays
                      .slice(week * 7, (week + 1) * 7)
                      .map((calenderDate: Date, index: number) => (
                        <StyledDateCell
                          key={`${index}`.toString()}
                          date={calenderDate}
                          isToday={isToday}
                          isDateSelected={isDateSelected}
                          isFutureDate={isFutureDate}
                          isCurrentMonthDate={isCurrentMonthDate}
                          onClick={() => handleDateClick(calenderDate)}
                        >
                          {calenderDate !== null ? calenderDate.getDate() : ''}
                        </StyledDateCell>
                      ))}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </StyledTable>
      </StyledTableContainer>
    );
  },
);
