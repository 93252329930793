import { useCallback, useState } from "react";

export type SwiperSwitchKey = 'generated' | 'original';

export const useSwiperSwitch = () => {
  const [swiperSwitchKey, setSwiperSwitchKey] = useState<SwiperSwitchKey>('generated');

  const handleResetSwiperSwitchKey = useCallback(() => {
    setSwiperSwitchKey('generated');
  }, [])

  return {
    swiperSwitchKey,
    setSwiperSwitchKey,
    handleResetSwiperSwitchKey,
  }
}
