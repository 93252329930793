import { Box, BoxProps } from '@/components/atoms/Box';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type BackgroundResultBox = {
  width?: number | string;
  height?: number | string;
  padding?: number | string;
  isDisabled?: boolean;
} & BoxProps;

export const StyledBackgroundResultBox = memo(
  ({
    width,
    height,
    padding,
    isDisabled,
    children,
  }: BackgroundResultBox) => {
    const resultBoxStyle = {
      width: width || '100%',
      height: height || '90px',
      padding: padding || '0px',
      borderRadius: '4px',
      cursor: isDisabled ? '' : 'pointer',
    };

    return (
      <Box className={vanilla.styledBackgroundResultBox} style={resultBoxStyle}>
        {children}
      </Box>
    );
  },
);

type BackgroundResultContentProps = {
  width?: number | string;
  height?: number | string;
  padding?: number | string;
  backgroundColor?: string;
} & BoxProps;

export const StyledBackgroundResultContent = memo(
  ({
    width,
    height,
    padding,
    backgroundColor,
    children,
    ...props
  }: BackgroundResultContentProps) => {
    const style = {
      width: width || '250px',
      height: height || '60px',
      padding: padding || '0px',
      background: backgroundColor || 'var(--color-gray-white)',
    };

    return (
      <Box
        className={vanilla.styledBackgroundResultContent}
        sx={style}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

type SettingResult = {
  isChecked?: boolean;
  isDisabled?: boolean;
  hasPadding?: boolean;
} & ImageProps;

export const StyledSettingResultImage = memo(
  ({
    isChecked,
    isDisabled,
    hasPadding,
    ...props
  }: SettingResult) => {
    const style = {
      border: isChecked
        ? `4px solid ${'var(--color-primary-deep)'}`
        : '4px solid transparent',
      padding: hasPadding ? '8px' : '0px',
      pointer: isDisabled ? '' : 'pointer',
    };

    return (
      <Image
        className={vanilla.styledSettingResultImage}
        style={style}
        {...props}
      />
    );
  },
);

export const StyledSettingImageThumbnailBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSettingImageThumbnailBox} {...props}>
        {children}
      </Box>
    );
  },
);

type SettingImageUpload = {
  isChecked?: boolean;
} & BoxProps;

export const StyledSettingImageUpload = memo(
  ({ isChecked, children, ...props }: SettingImageUpload) => {
    const style = {
      border: isChecked
        ? `4px solid ${'var(--color-primary-deep)'}`
        : '4px solid transparent',
    };

    return (
      <Box className={vanilla.styledSettingImageUpload} sx={style}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxHeight260 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight260} {...props}>
      {children}
    </Box>
  );
});

type TriangleWrapperProps = {
  trianglePositionType: 'left' | 'leftOfCenter' | 'rightOfCenter' | 'right';
} & BoxProps;
export const StyledTriangleWrapper = memo(
  ({ trianglePositionType, children, ...props }: TriangleWrapperProps) => {
    let paddingLeft;
    switch (trianglePositionType) {
      case 'left':
        paddingLeft = '60px';
        break;
      case 'leftOfCenter':
        paddingLeft = '90px';
        break;
      case 'rightOfCenter':
        paddingLeft = '170px';
        break;
      case 'right':
        paddingLeft = '200px';
        break;
      default:
        throw new Error("trianglePositionTypeの値が不適切です");
    }

    const style = {
      paddingLeft,
    };

    return (
      <Box className={vanilla.styledTriangleWrapper} sx={style} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledTrianglePosition = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledTrianglePosition} {...props}>
        {children}
      </Box>
    );
  },
);
