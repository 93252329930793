import { Button } from '@/components/atoms/Button';
import { memo } from 'react';

type Props = {
  handleClickApplySupportWithSelectImageButton: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
};

export const SupportApplyButton = memo(
  ({
    handleClickApplySupportWithSelectImageButton,
    checkedCount,
  }: Props): JSX.Element => {
    return (
      <Button
        style={{ width: '100%', fontSize: 15 }}
        onClick={handleClickApplySupportWithSelectImageButton}
        disabled={checkedCount === 0}
        mode='teal'
      >
        選択した画像でサポートを申請
        {checkedCount ? `（${checkedCount}）` : ''}
      </Button>
    );
  },
);
