import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { SwiperSwitchKey } from '@/features/components/organisms/DownloadDialogViewer/SwiperSwitch/hooks/useSwiperSwitch';
import { memo, useMemo } from 'react';

type Props = {
  handleClickApplySupportWithSelectImageButton: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
  bottom?: number;
  swiperSwitchKey: SwiperSwitchKey;
};

// TODO: absoluteを使わない形に修正する（レイアウトから修正する必要があるのでまぁまぁ時間かかりそう）
// MEMO: ボタンの位置をabsoluteで指定してしまっているので、複数ボタンを配置した際レイアウト崩れが起きてしまう。そのためワークアラウンドとしてbottomをpropsで渡してボタン位置をコントロールできるようにした
export const SupportApplyButton = memo(
  ({
    handleClickApplySupportWithSelectImageButton,
    checkedCount,
    bottom = -10,
    swiperSwitchKey,
  }: Props): JSX.Element => {
    const isDisabled = useMemo(() => {
      return checkedCount === 0 || swiperSwitchKey === 'original';
    }, [
      checkedCount,
      swiperSwitchKey,
    ])

    return (
      <Box style={{ position: 'absolute', bottom, left: -40 }}>
        <Button
          style={{ width: 360, fontSize: 15 }}
          onClick={handleClickApplySupportWithSelectImageButton}
          disabled={isDisabled}
          mode='teal'
        >
          選択した画像でサポートを申請
          {checkedCount ? `（${checkedCount}）` : ''}
        </Button>
      </Box>
    );
  },
);
