import { StyledGridGap10 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { FileDetail } from '@/features/components/atoms/FileDetail';
import { GlobalSegmentation } from '@/features/components/organisms/GlobalSegmentation';
import { Buttons } from '@/features/components/steps/Segmentation/Buttons';
import { Functions } from '@/features/components/steps/Segmentation/Functions';
import { useInitializeSamType } from '@/features/components/steps/Segmentation/hooks/useInitializeSamType';
import {
  StyledPreview,
  StyledPreviewSlide,
} from '@/features/components/styled/preview';
import { FeaturesModalStepLayout } from '@/features/components/templates/Layout/FeaturesModalStepLayout';
import { memo } from 'react';
import { useHandlers } from '../hooks/useHandlers';
import { StyledSegmentationFunctionBox } from '../styled';

type Props = {
  field: ImageFields;
  onSubmit: () => void;
};

/**
 * JSDoc
 * @see セグメンテーションステップ
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Segmentation = memo(({ field, onSubmit }: Props): JSX.Element => {
  const { handleResetImageFromModal } = useHandlers();
  useInitializeSamType({ field });
  const { featureData, updateFeatureData } = useFeaturesContext({});

  return (
    <>
      <FeaturesModalStepLayout>
        <StyledGridGap10>
          <StyledPreview swiperIndex={0} maxIndex={0} width={660} height={600}>
            <StyledPreviewSlide
              width={512}
              height={600}
            >
              {featureData && updateFeatureData && (
                <GlobalSegmentation
                  field={field}
                  isKeepMask
                  data={featureData}
                  updateData={updateFeatureData}
                />
              )}
            </StyledPreviewSlide>
          </StyledPreview>
        </StyledGridGap10>
        <StyledSegmentationFunctionBox>
          <Functions field={field} isKeepMask />
          <FileDetail
            field={field}
            onResetImage={handleResetImageFromModal}
            isKeepMask
            onDialog
          />
          <Buttons field={field} onSubmit={onSubmit} isKeepMask />
        </StyledSegmentationFunctionBox>
      </FeaturesModalStepLayout>
    </>
  );
});
