import { Task } from '@/api/schemas';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useEffect, useMemo } from 'react';

type Props = {
  taskApiResponse?: Task;
};

export const useInitializeDownloadDialog = ({
  taskApiResponse,
}: Props) => {
  const { setDownloadedArray } = useCheckArray();

  const hasPsd = useMemo(() => {
    // MEMO: すべての画面でPSDダウンロードできることになったが、仕様変更や画面追加のことを考えて制御ロジックだけ残しておく。
    // return (
    //   targetModeEn !== 'colorTemperature' &&
    //   targetModeEn !== 'backgroundLora' &&
    //   targetModeEn !== 'multiWhite'
    // );
    return true
  }, []);

  const isNoBackground = useMemo(() => {
    return taskApiResponse?.parameters?.noBackground ?? false;
  }, [taskApiResponse?.parameters?.noBackground]);

  /* ダウンロード済みの画像をdownloadedArrayに格納する */
  /*
    ダウンロードダイアログのコンテンツ内でタスクAPIのdownloadedAtを直接参照すると
    ダウンロード時にもう一度APIを叩く必要があるためここでdownloadedAtを取得している。
    useDownload内のhandleUpdateCheckArrayAndDownloadedArrayでcheckArrayとdownloadedArrayを更新している。
  */
  useEffect(() => {
    if (!taskApiResponse?.result.resultImages) return;
    setDownloadedArray(
      taskApiResponse?.result.resultImages?.map(
        (imageResult: ImageResult) => !!imageResult.downloadedAt,
      ) ?? [],
    );
  }, [taskApiResponse?.result.resultImages, setDownloadedArray]);

  return { hasPsd, isNoBackground };
};
