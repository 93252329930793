import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useCallback } from 'react';
import { DownloadDialogMode } from '../DownloadDialog';

type Props = {
  setDownloadDialogMode: React.Dispatch<React.SetStateAction<DownloadDialogMode>>;
}
export const useSupport = ({
  setDownloadDialogMode
}: Props) => {
  // MEMO: サポートボタンに関する処理（必要あれば別ファイルに切り出す）
  const { handleCheckAll, handleUnCheckAll } = useCheckArray();
  // サポートモード開始処理
  const handleClickSupportStartButton = useCallback(() => {
    handleUnCheckAll();
    setDownloadDialogMode('selectSupportImage')
  }, [
    handleUnCheckAll,
    setDownloadDialogMode,
  ])
  // サポートモードキャンセル処理
  const handleClickSupportCancelButton = useCallback(() => {
    setDownloadDialogMode('selectDownloadImage')
    // 選択画像をダイアログの開いた状態にリセットする
    handleCheckAll();
  }, [
    handleCheckAll,
    setDownloadDialogMode,
  ])
  // 『選択した画像でサポートを申請』処理（実際には申請実行ではなく、フォームに遷移する処理）
  const handleClickApplySupportWithSelectImageButton = useCallback(() => {
    setDownloadDialogMode('supportForm')
  }, [
    setDownloadDialogMode,
  ])

  return {
    handleClickSupportStartButton,
    handleClickSupportCancelButton,
    handleClickApplySupportWithSelectImageButton
  };
};
