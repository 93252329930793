import { StyledBoxHeight100vh, StyledBoxHeight30 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ResponsiveWrapper } from '@/features/components/molecules/ResponsiveWrapper';
import { ContentHeader } from '@/features/components/organisms/ContentHeader';
import { HistoryTab } from '@/features/components/organisms/HistoryTab';
import {
  StyledScrollBox,
  StyledSplitRightBox,
  StyledStepContentBox,
  StyledStepLeftBox,
  StyledStepLeftCentering,
} from '@/features/components/templates/Layout/styled';
import { memo, useState } from 'react';
import {
  StyledExplainBox,
  StyledExplainNumber,
  StyledExplainText,
} from './styled';

type Props = {
  children: React.ReactNode[];
  isHistoryDisabled?: boolean;
};

export const FeaturesStepLayout = memo(({
  children,
  isHistoryDisabled = false,
}: Props): JSX.Element => {
  const [leftNode, rightNode] = children;
  const { featureData, findActiveStep } = useFeaturesContext({});
  const [isActiveHistoryTab, setIsActiveHistoryTab] = useState(true);

  return (
    <>
      <ContentHeader />
      <StyledBoxHeight100vh>
        <StyledExplainBox>
          <>
            <StyledExplainNumber>{findActiveStep()?.num}</StyledExplainNumber>
            <StyledExplainText>{findActiveStep()?.explain}</StyledExplainText>
          </>
        </StyledExplainBox>
        <StyledStepContentBox>
          <StyledStepLeftCentering>
            <StyledBoxHeight30 />
            <StyledStepLeftBox>
              <ResponsiveWrapper>{leftNode}</ResponsiveWrapper>
            </StyledStepLeftBox>
            <HistoryTab
              isActive={isActiveHistoryTab}
              onClick={() => {
                setIsActiveHistoryTab(!isActiveHistoryTab);
              }}
              isDisabled={isHistoryDisabled}
            />
          </StyledStepLeftCentering>
          <StyledSplitRightBox>
            <StyledScrollBox>{rightNode}</StyledScrollBox>
          </StyledSplitRightBox>
        </StyledStepContentBox>
      </StyledBoxHeight100vh>
    </>
  );
});
