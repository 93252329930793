import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { MultiUploadImages } from '@/components/icons/others';
import {
  StyledBoxHeight10,
  StyledFlexCenter,
  StyledGridGap5,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { getBatchLimitImagesByActiveFeature } from '@/utils/batchUpload';
import { memo } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import {
  StyledDragDropZone,
  StyledDragDropZoneFontStyle,
  StyledUploadInterface,
} from './styled';

type Props = {
  targetId: string;
  targetNumber: number;
  disabled: boolean;
  warningFunction: WarningFunction;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
};

export const UploadSetInterface = memo(
  ({
    targetId,
    targetNumber,
    disabled,
    warningFunction,
    getRootProps,
    getInputProps,
  }: Props): JSX.Element => {
    const { activeFeatureName } = useFeaturesContext({});

    const { handleChangeCurrentSet } =
      useBatchUpload();

    return (
      <>
        {/* biome-ignore lint/suspicious/noEmptyBlockStatements: */}
        <StyledUploadInterface {...(disabled ? () => {} : getRootProps())}>
          <input
            {...(disabled
              ? { type: 'hidden' }
              : getInputProps({
                  className: `dropzone${targetNumber}-input`,
                }))}
          />
          <StyledDragDropZone
            onClick={() => {
              disabled && handleChangeCurrentSet(targetId);
              warningFunction.resetWarnings();
            }}
          >
            <StyledDragDropZoneFontStyle>
              <StyledGridGap5 style={{ justifyContent: 'center' }}>
                <StyledFlexCenter>
                  <MultiUploadImages />
                </StyledFlexCenter>
                { activeFeatureName === 'multiWhite' ? '背景を白抜きする画像を' : '調整する画像を'}
                <br />
                ドラッグ＆ドロップ
                <Box style={{ fontSize: 16, color: 'var(--color-gray-main)' }}>
                  複数の画像（上限{getBatchLimitImagesByActiveFeature(activeFeatureName)}枚まで）
                </Box>
              </StyledGridGap5>
              <StyledBoxHeight10 />
              <Button style={{ fontSize: 15 }} disabled={disabled}>
                ファイルを選択
              </Button>
            </StyledDragDropZoneFontStyle>
          </StyledDragDropZone>
        </StyledUploadInterface>
      </>
    );
  },
);
