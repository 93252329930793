import { ImageResult } from '@/contexts/FeaturesContext/types';
import { Swiper } from '@/features/components/organisms/GlobalViewer/Swiper';
import { SwiperThumbs } from '@/features/components/organisms/GlobalViewer/SwiperThumbs';
import { AnimatePresence } from 'framer-motion';
import React, { memo, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { SupportApplyButton } from './SupportApplyButton/SupportApplyButton';
import {
  StyledGlobalViewerContainer,
  StyledSwiperMotionDiv,
  StyledSwiperThumbsMotionDiv,
} from './styled';

export type GlobalViewerMode = 'viewPreview' | 'selectDownloadImage' | 'selectSupportImage' | 'supportForm';

type Props = {
  imageResults: ImageResult[];
  label?: React.ReactNode;
  hasAnimation?: boolean;
  hasCheckIcon?: boolean;
  repeatBackgroundImage?: string;
  taskType?: string;
  globalViewerMode?: GlobalViewerMode;
  taskId: string | undefined;
  isOpenSupportAccordion: boolean;
  checkedCount: number;
  handleClickSupportCancelButton: () => void;
  handleClickApplySupportWithSelectImageButton: () => void;
};

export const GlobalViewer = memo(
  ({
    imageResults,
    label,
    hasAnimation = false,
    hasCheckIcon = false,
    repeatBackgroundImage,
    taskType,
    globalViewerMode,
    taskId,
    isOpenSupportAccordion,
    checkedCount,
    handleClickSupportCancelButton,
    handleClickApplySupportWithSelectImageButton,
  }: Props) => {
    // biome-ignore lint/suspicious/noExplicitAny: あとで修正
    const [swiperRef, setSwiperRef] = useState<any>(null);
    const [swiperIndex, setSwiperIndex] = useState(0);

    const variant = useMemo(() => {
      return globalViewerMode === 'selectSupportImage' || globalViewerMode === 'supportForm' ? 'teal' : 'primary'
    }, [globalViewerMode]);

    const isDisplaySupportApplyButton = useMemo(() => {
      return globalViewerMode === 'selectSupportImage';
    }, [globalViewerMode]);

    return (
      <StyledGlobalViewerContainer>
        <AnimatePresence initial={hasAnimation}>
          <StyledSwiperMotionDiv
            key="viewer-left"
            initial={{ x: hasAnimation ? 100 : 0, opacity: 0.5 }}
            animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
          >
            <Swiper
              imageResults={imageResults}
              swiperRef={swiperRef}
              setSwiperRef={setSwiperRef}
              swiperIndex={swiperIndex}
              setSwiperIndex={setSwiperIndex}
              repeatBackgroundImage={repeatBackgroundImage}
              taskType={taskType}
              variant={variant}
              hasCheckIcon={hasCheckIcon}
            />
          </StyledSwiperMotionDiv>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              // height: '100%',
            }}
          >
            <StyledSwiperThumbsMotionDiv
              key="viewer-right"
              initial={{ x: hasAnimation ? -100 : 0, opacity: 0.5 }}
              animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
              transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
            >
              <SwiperThumbs
                imageResults={imageResults}
                label={label}
                hasCheckIcon={hasCheckIcon}
                thumbnailBoxWidth={420}
                swiperRef={swiperRef}
                setSwiperRef={setSwiperRef}
                swiperIndex={swiperIndex}
                setSwiperIndex={setSwiperIndex}
                repeatBackgroundImage={repeatBackgroundImage}
                variant={variant}
                globalViewerMode={globalViewerMode}
                taskId={taskId}
                isOpenSupportAccordion={isOpenSupportAccordion}
                handleClickSupportCancelButton={handleClickSupportCancelButton}
              />
            </StyledSwiperThumbsMotionDiv>
            {isDisplaySupportApplyButton && (
              <div
                style={{
                  paddingLeft: '16px',
                  width: '100%',
                }}
              >
                <SupportApplyButton
                  handleClickApplySupportWithSelectImageButton={handleClickApplySupportWithSelectImageButton}
                  checkedCount={checkedCount}
                />
              </div>
            )}
          </div>
        </AnimatePresence>
      </StyledGlobalViewerContainer>
    );
  },
);
