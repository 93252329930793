import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useCallback } from 'react';

export const useHistoryTabHandlers = (isDisabled: boolean) => {
  const { featureData, activateTargetStep, findActiveStep, activeFeatureName } =
    useFeaturesContext({});

  const handleClickStepThumbnail = useCallback(
    (name: string) => {
      switch (name) {
        case 'upload':
          // 押せない
          activateTargetStep('segmentation');
          break;
        case 'segmentation':
          // セグメンテーションのパラメーターはリセットして状態
          activateTargetStep(name);
          break;
        case 'setting':
          // 設定のパラメーターはリセットしない＝設定終わり
          activateTargetStep(name);
          break;
        case 'download':
          activateTargetStep(name);
          break;
        default:
          break;
      }
    },
    [activateTargetStep],
  );

  const canClick = (name: string) => {
    if (isDisabled) return false

    if (name === 'upload') {
      return false;
    }
    if (name === 'segmentation' && !featureData?.mainImage.base64) {
      return false;
    }
    if (name === 'download' && !featureData?.single?.genStatus.isGenerated) {
      return false;
    }
    if (
      name === 'setting' &&
      (!featureData?.single?.genResult?.generatedImageResults[0]?.url ||
        activeFeatureName === 'white')
    ) {
      return false;
    }
    if (name === findActiveStep()?.name) {
      return false;
    }

    return true;
  };

  const imageSrc = (name: string) => {
    switch (name) {
      case 'upload':
        return featureData?.mainImage.base64;
      case 'segmentation':
        return featureData?.mainImage.combinedBase64;
      case 'setting':
        // d=72x72を設定するとアップロード等のサムネと見た目が異なる
        return featureData?.single?.genResult?.generatedImageResults[0]?.url
          ? `${featureData?.single?.genResult?.generatedImageResults[0]?.url}`
          : '';
      case 'download':
        // d=72x72を設定するとアップロード等のサムネと見た目が異なる
        return featureData?.single?.genResult?.image
          ? `${featureData?.single?.genResult?.image}`
          : '';

      default:
        return '';
    }
  };

  return {
    handleClickStepThumbnail,
    canClick,
    imageSrc,
  };
};
