import { useDialog } from '@/hooks/global/useDialog';
import { useEffect, useState } from 'react';

type Props = {
  ref: React.RefObject<HTMLElement>;
  onClickAway: () => void;
};

export const useClickAway = ({ ref, onClickAway }: Props) => {
  const [isEnable, setIsEnable] = useState(false);

  // このカスタムフックを参照しているコンポーネントがclickイベントによってマウントされる場合、表示と同時にonClickAwayが実行されるのを防ぐuseEffect
  useEffect(() => {
    setTimeout(() => {
      setIsEnable(true);
    }, 1000);
  })

  // TODO: 現状LocalのuseDialogを使っている箇所の状態を監視できない。
  const { isOpenDialog } = useDialog();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isEnable || isOpenDialog) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway();
      }
    };

    // MEMO: mousedownイベントはトラックパッドのタップでは反応しないため、clickイベントで対応。（mousedownイベントであればコンポーネントマウント時にイベントハンドラが実行されないようにできるが、しょうがない）
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [
    ref,
    onClickAway,
    isOpenDialog,
    isEnable,
  ]);
};
