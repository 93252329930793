import DummyImage from '@/assets/any/dummy.png';
import houndstooth from '@/assets/any/houndstooth.png';
import { Box } from '@/components/atoms/Box';
import { Tooltip } from '@/components/atoms/Tooltip';
import { StyledBoxWidth10 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureStep } from '@/contexts/FeaturesContext/types';
import { useGetResizedSize } from '@/hooks/utils/useGetResizedSize';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useHistoryTabHandlers } from '../hooks/useHistoryTabHandlers';
import {
  StyledImage,
  StyledThumbnailBox,
  StyledThumbnailNumberBox,
} from '../styled';
import { Circle } from './Circle';
import { KeepMaskCircle } from './KeepMaskCircle';

type Props = {
  step: FeatureStep;
  num: number;
  activeStepName: string;
  isDisabled: boolean;
};

export const ThumbnailWithNumber = memo(
  ({
    step,
    num,
    activeStepName,
    isDisabled,
  }: Props): JSX.Element => {
    const { featureData, activeFeatureName } = useFeaturesContext({});
    const { handleClickStepThumbnail, canClick, imageSrc } =
      useHistoryTabHandlers(isDisabled);
    const { imageWidth, imageHeight } = useGetResizedSize({
      imageUrl: featureData?.single?.genResult.generatedImageResults[0]?.url,
      maxWidth: 72,
      maxHeight: 72,
    });

    return (
      <div
        style={{
          // MEMO: styledImageの方でグレースケール制御しているのでそちらに合わせた方が良いかもしれない。時間がかかりそうなのでいったん簡単にここで制御する。
          filter: isDisabled ? 'grayscale(1) opacity(0.5)' : 'none',
          cursor: isDisabled ? 'inherit' : 'pointer',
        }}
      >
        {step.name !== 'repeat' && (
          <>
            <motion.div
              whileHover={{ scale: canClick(step.name) ? 1.2 : 1 }}
              style={{ transformOrigin: 'bottom', position: 'relative' }}
            >
              <Box>
                <Tooltip title={step.title} placement="top">
                  <StyledThumbnailBox
                    onClick={() =>
                      canClick(step.name) && handleClickStepThumbnail(step.name)
                    }
                    stepName={step.name}
                    canClick={canClick(step.name)}
                    isNull={!imageSrc(step.name)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      verticalAlign: 'middle',
                    }}
                  >
                    <Box
                      style={{
                        width: 72,
                        height: 72,
                        position: 'relative',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <>
                        <StyledThumbnailNumberBox
                          stepName={step.name}
                          isActive={activeStepName === step.name}
                          style={{ position: 'absolute', top: 0, left: 0 }}
                        >
                          {num}
                        </StyledThumbnailNumberBox>
                        {imageSrc(step.name) && (
                          <StyledImage
                            src={imageSrc(step.name) || DummyImage}
                            width={imageWidth || 72}
                            height={imageHeight || 72}
                            stepName={step.name}
                            isActive={activeStepName === step.name}
                            isRepeat={false}
                            alt={`pict${num}`}
                            style={{
                              position: 'relative',
                              objectFit: 'contain',
                              backgroundImage:
                                (step.name === 'setting' ||
                                  step.name === 'download') &&
                                houndstooth &&
                                imageWidth &&
                                imageHeight
                                  ? `url(${houndstooth.src})`
                                  : '',
                              width: imageWidth || 72,
                              height: imageHeight || 72,
                            }}
                          />
                        )}
                        {!featureData?.mainImage.base64 && (
                          <Box style={{ width: 72, height: 72 }} />
                        )}
                      </>
                      {step.name === 'segmentation' &&
                        activeFeatureName === 'item' && <KeepMaskCircle />}
                      {step.name === 'setting' && (
                        <Circle activeStepName={activeStepName} />
                      )}
                    </Box>
                  </StyledThumbnailBox>
                </Tooltip>
              </Box>
            </motion.div>
            <StyledBoxWidth10 />
          </>
        )}
      </div>
    );
  },
);
