import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Popper } from '@/components/molecules/Popper';
import { DownloadFormatMenu } from '@/features/components/modeless/PopupDownloadFormat/DownloadFormatMenu';
import { useDownload } from '@/hooks/global/useDownload';
import { PopperProps } from '@mui/material/Popper/Popper';
import { UseQueryResult } from '@tanstack/react-query';
import { memo, useCallback, useEffect, useState } from 'react';

type Props = {
  anchorElement: HTMLElement | null;
  isOpenPopper: boolean;
  handleClosePopper: () => void;
  popperTitle: string;
  popperPlacement: PopperProps['placement'];
  taskApiResponse: Task | undefined;
  refetchTasks: UseQueryResult['refetch'];
  boxShadow?: string;
  isNoBackground: boolean;
  hasPsd?: boolean;
};

export const PopupDownloadFormat = memo(
  ({
    popperTitle,
    popperPlacement,
    anchorElement,
    isOpenPopper,
    handleClosePopper,
    taskApiResponse,
    refetchTasks,
    boxShadow,
    isNoBackground,
    hasPsd,
  }: Props): JSX.Element => {
    const { isDownloaded, setIsDownloaded, handleDownloadWithApi } =
      useDownload({
        taskApiResponse,
      });
    const [format, setFormat] = useState('png');
    const [isDownloading, setIsDownloading] = useState(false);

    const handleClick = useCallback(() => {
      setIsDownloading(true);
      const handleAsyncOperations = async () => {
        await handleDownloadWithApi(format);
        refetchTasks();
      };
      // biome-ignore lint/complexity/noVoid: あとで修正
      void handleAsyncOperations();
    }, [format, handleDownloadWithApi, refetchTasks]);

    useEffect(() => {
      if (isDownloaded) {
        setIsDownloading(false);
        setIsDownloaded(false);
      }
    }, [isDownloaded, setIsDownloaded]);

    return (
      <>
        { isOpenPopper &&
        <Popper
          title={popperTitle}
          titleWeight="bold"
          onClose={handleClosePopper}
          anchorElement={anchorElement as unknown as HTMLElement}
          placement={popperPlacement as PopperProps['placement']}
          placementY={25}
          closeIconSize={24}
          boxShadow={boxShadow}
          zIndex={9999999999}
        >
          <Box>
            <DownloadFormatMenu
              format={format}
              setFormat={setFormat}
              isDialog={false}
              onClick={handleClick}
              onClose={handleClosePopper}
              isDownloading={isDownloading}
              isDownloaded={isDownloaded}
              isNoBackground={isNoBackground}
              hasPsd={hasPsd}
            />
          </Box>
        </Popper>
        }
      </>
    );
  },
);
