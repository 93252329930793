import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { TabButton } from '@/features/components/atoms/TabButton/TabButton';
import { ResultDisplayName } from '@/features/components/functions/ResultDisplayName';
import { BackgroundPresetDialog } from '@/features/components/modals/BackgroundPresetDialog/BackgroundPresetDialog';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { memo } from 'react';
import { useTargetTypeHandlers } from '../hooks/useTargetTypeHandlers';
import { ResultBox } from './ResultBox';
import {
  StyledTabButtonList,
  StyledTabButtonListChild,
  StyledTitle,
} from './styled';

type Props = {
  handleClickFeatureTargetType: (target: string) => void;
  handleResetAfterPreview: () => void;
  isDisabled: boolean;
};
export const TargetTypeForBackground = memo(
  ({
    handleClickFeatureTargetType,
    handleResetAfterPreview,
    isDisabled,
  }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});

    const {
      isOpenDialog,
      handleOpenDialog,
      handleCloseDialog,
      handleSubmit,
      isOpenPresetDialog,
      handleOpenPresetDialog,
      handleClosePresetDialog,
      handleSubmitPreset,
    } = useTargetTypeHandlers({ handleResetAfterPreview });

    return (
      <>
        <StyledTitle>背景のタイプ</StyledTitle>
        <StyledTabButtonList>
          <StyledTabButtonListChild>
            <TabButton
              onClick={() => {
                handleClickFeatureTargetType('image');
              }}
              label="画像"
              isActive={featureData?.single?.param?.targetType === 'image'}
              isDisabled={isDisabled}
            />
          </StyledTabButtonListChild>

          <StyledTabButtonListChild>
            <TabButton
              onClick={() => {
                handleClickFeatureTargetType('preset');
              }}
              label="プリセット"
              isActive={featureData?.single?.param?.targetType === 'preset'}
              isDisabled={isDisabled}
            />
          </StyledTabButtonListChild>
        </StyledTabButtonList>
        <ResultBox
          handleOpenDialog={handleOpenDialog}
          handleOpenPresetDialog={handleOpenPresetDialog}
          isDisabled={isDisabled}
        />
        {featureData?.single?.param?.targetType === 'image' &&
          featureData?.single?.param?.refImageName && (
            <ResultDisplayName
              displayName={featureData.single.param.refImageName}
            />
          )}
        {featureData?.single?.param?.targetType === 'preset' &&
          featureData?.single?.param?.presetName && (
            <ResultDisplayName
              displayName={featureData.single.param.presetName}
            />
          )}
        <UploadRefWithOptionalSam
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          dialogTitle="参照する画像をアップロード"
          dialogExplain="背景を参照する画像をアップロードしてください"
          onSubmit={handleSubmit}
        />
        <BackgroundPresetDialog
          isOpen={isOpenPresetDialog}
          onClose={handleClosePresetDialog}
          dialogTitle="背景プリセットを選択"
          onSubmit={handleSubmitPreset}
        />
      </>
    );
  },
);
