import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { AccordionWrapper } from '@/features/components/molecules/AccordionWrapper';
import { useAccordionContext } from '@/features/components/molecules/AccordionWrapper/hooks/useAccordionContext';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType, _TaskParameters } from '@/features/components/steps/BatchUpload/types';
import hexToRgba from 'hex-to-rgba';
import { memo, useMemo } from 'react';
import { Bar } from './Bar';
import { Uploader } from './Uploader';

type Props = {
  set: FeatureBatch;
  targetNumber: number;
  handleOpenSetNameDialog: () => void;
  handleOpenDeleteMenu: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string,
    setName: string,
  ) => void;
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
  _taskParameters: _TaskParameters;
};

export const AccordionRow = memo(
  ({
    set,
    targetNumber,
    handleOpenSetNameDialog,
    handleOpenDeleteMenu,
    setFunctionViewType,
    warningFunction,
    _taskParameters,
  }: Props): JSX.Element => {
    const {
      handleChangeCurrentSet,
      checkUploadImageCondition,
      currentNumber,
      batchConstant,
    } = useBatchUpload();
    const { accordionStates, handleChangeAccordionState } = useAccordionContext(
      {
        currentNumber,
      },
    );

    const isOpenAccordion = useMemo(() => {
      if (!accordionStates[targetNumber]) return true;

      return accordionStates[targetNumber].isOpen;
    }, [accordionStates, targetNumber]);

    return (
      <>
        <AccordionWrapper
          isOpenAccordion={isOpenAccordion}
          backgroundColor={
            currentNumber === targetNumber ? hexToRgba('#e3f2fd', 0.6) : '#fff'
          }
        >
          <Bar
            set={set}
            handleChangeCurrentSet={handleChangeCurrentSet}
            checkUploadImageCondition={checkUploadImageCondition}
            handleOpenSetNameDialog={handleOpenSetNameDialog}
            handleChangeAccordionState={handleChangeAccordionState}
            handleOpenDeleteMenu={handleOpenDeleteMenu}
            batchConstant={batchConstant}
            isOpenAccordion={isOpenAccordion}
          />
          <Uploader
            targetId={set.id}
            targetNumber={targetNumber}
            setFunctionViewType={setFunctionViewType}
            warningFunction={warningFunction}
            variant='multiSet' // MEMO: AccordionRowを使っているときはmultiSet固定
            _taskParameters={_taskParameters}
          />
        </AccordionWrapper>
      </>
    );
  },
);
