import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { EditSetNameDialog } from '@/features/components/modals/EditSetNameDialog';
import { useAccordionContext } from '@/features/components/molecules/AccordionWrapper/hooks/useAccordionContext';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType, _TaskParameters } from '@/features/components/steps/BatchUpload/types';
import { useDialog } from '@/hooks/local/useDialog';
import { memo, useMemo } from 'react';
import { Body } from './Body';
import { Head } from './Head';

type SetTableProps = {
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
  _taskParameters: _TaskParameters;
};

/**
 * @see バッチアップロードのセットの状態を表示する親コンポーネント
 * @see HEAD カラム
 * @see BODY データ
 * @see EditSetNameDialog セット名変更のダイアログ
 */
export const SetTable = memo(
  ({
    setFunctionViewType,
    warningFunction,
    _taskParameters,
  }: SetTableProps): JSX.Element => {
    const { activeFeatureName } = useFeaturesContext({});
    const { currentNumber, batchConstant } = useBatchUpload();
    const {
      isOpenDialog: isOpenSetNameDialog,
      handleOpenDialog: handleOpenSetNameDialog,
      handleCloseDialog: handleCloseSetNameDialog,
    } = useDialog();

    const {
      isDisabledAccordionAll,
      accordionAllButtonObject,
      handleClickAllAccordion,
    } = useAccordionContext({ isInitial: true, currentNumber });

    const isSingleSet = useMemo(() => {
      return activeFeatureName === 'multiWhite';
    }, [activeFeatureName])

    return (
      <>
        { !isSingleSet &&
        <Head
          isDisabledAccordionAll={isDisabledAccordionAll}
          batchConstant={batchConstant}
          accordionAllButtonObject={accordionAllButtonObject}
          handleClickAllAccordion={handleClickAllAccordion}
        />
        }
        <Body
          handleOpenSetNameDialog={handleOpenSetNameDialog}
          setFunctionViewType={setFunctionViewType}
          warningFunction={warningFunction}
          isSingleSet={isSingleSet}
          _taskParameters={_taskParameters}
        />
        { !isSingleSet &&
        <EditSetNameDialog
          isOpenDialog={isOpenSetNameDialog}
          handleCloseDialog={handleCloseSetNameDialog}
          featureName='batchUpload'
        />
        }
      </>
    );
  },
);
