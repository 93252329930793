import { Box } from '@/components/atoms/Box';
import {
  StyledFlexCenter,
  StyledFlexGap8,
  StyledGridEnd,
  StyledGridGap25,
} from '@/components/styled';
import {
  StyledFunctionClearButton,
  StyledFunctionNextButton,
  StyledFunctionPreviewButton,
} from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { NoBackgroundSwitch } from '@/features/components/organisms/NoBackgroundSwitch';
import { useRequestPreview } from '@/features/components/steps/Segmentation/hooks/useRequestPreview';
import { memo } from 'react';

type Props = {
  field: ImageFields;
  onSubmit: () => void;
  isKeepMask?: boolean;
};

/**
 * JSDoc
 * @see セグメンテーションのボタンコンポーネント
 * @see manualの場合のみプレビューとクリアボタンを表示
 * @param {Props} { field }
 * @returns {JSX.Element}
 */
export const Buttons = memo(
  ({ field, onSubmit, isKeepMask }: Props): JSX.Element => {
    const { activeFeatureName } = useFeaturesContext({});
    const { handleRequestPreview, handleClearPoints } = useRequestPreview({
      field,
      isKeepMask,
    });
    const { featureData } = useFeaturesContext({});
    const positiveKey = !isKeepMask ? 'positivePoints' : 'kmPositivePoints';
    const negativeKey = !isKeepMask ? 'negativePoints' : 'kmNegativePoints';
    const isPreviewCompletedKey = !isKeepMask
      ? 'isPreviewCompleted'
      : 'isKmPreviewCompleted';

    return (
      <>
        <StyledGridEnd>
          <StyledFlexCenter>
            <StyledGridGap25>
              {activeFeatureName === 'white' && (
                <NoBackgroundSwitch field={field} isKeepMask={isKeepMask} />
              )}
              <>
                {featureData?.[field].samType === 'manual' ? (
                  <>
                    <Box style={{ height: 30 }} />
                    <StyledFlexGap8>
                      <StyledFunctionPreviewButton
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleRequestPreview}
                        disabled={
                          featureData?.[field][isPreviewCompletedKey] ||
                          !featureData?.[field][positiveKey].length
                        }
                      >
                        プレビュー
                      </StyledFunctionPreviewButton>
                      <StyledFunctionClearButton
                        onClick={handleClearPoints}
                        disabled={
                          !(
                            featureData?.[field][positiveKey].length ||
                            featureData?.[field][negativeKey].length
                          )
                        }
                      >
                        クリア
                      </StyledFunctionClearButton>
                    </StyledFlexGap8>
                  </>
                ) : (
                  <>
                    {activeFeatureName === 'colorTemperature' && (
                      <Box style={{ height: 214 }} />
                    )}
                  </>
                )}
                <StyledFunctionNextButton
                  disabled={!featureData?.[field][isPreviewCompletedKey]}
                  onClick={onSubmit}
                >
                  {activeFeatureName === 'background'
                    ? '次へ'
                    : 'この領域で変更'}
                </StyledFunctionNextButton>
              </>
              <Box />
            </StyledGridGap25>
          </StyledFlexCenter>
        </StyledGridEnd>
      </>
    );
  },
);
