import { Box } from '@/components/atoms/Box';
import { StyledFlexGap10, StyledFont16Bold } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ResultDisplayName } from '@/features/components/functions/ResultDisplayName';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { memo } from 'react';
import { useTargetTypeHandlers } from '../hooks/useTargetTypeHandlers';
import { ResultBox } from './ResultBox';

type Props = {
  handleResetAfterPreview: () => void;
  isDisabled: boolean;
};
export const TargetTypeForItem = memo(
  ({
    handleResetAfterPreview,
    isDisabled,
  }: Props): JSX.Element => {
    const { isOpenDialog, handleOpenDialog, handleCloseDialog, handleSubmit } =
      useTargetTypeHandlers({ handleResetAfterPreview });
    const { featureData } = useFeaturesContext({});

    return (
      <>
        <StyledFlexGap10>
          <StyledFont16Bold>参照するアイテム画像</StyledFont16Bold>
        </StyledFlexGap10>
        <Box />
        <ResultBox
          isDisabled={isDisabled}
          handleOpenDialog={handleOpenDialog}
        />
        {featureData?.single?.param?.targetType === 'image' &&
          featureData?.single?.param?.refImageName && (
            <ResultDisplayName
              displayName={featureData.single.param.refImageName}
            />
          )}
        <UploadRefWithOptionalSam
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          dialogTitle="参照する画像をアップロード"
          dialogExplain="アイテムを参照する画像をアップロードし、参照範囲を指定してください"
        />
      </>
    );
  },
);
