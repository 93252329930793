import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Ffont.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6WVz06EMBDG7zxFEy9rYglU9o%2Fs3YMHY%2BITdKGUutBiqQus8d3doLIV0G4h4ULnm1%2Bn8xXGlUmD6qMH3h0AEsEVTHDOsiYEj0IJ8Ix5CR6ets6H434p%2FbOyIoymKgSB52kCNBSsfwluz4KSHUkI%2FKCot6elSGRChuCA5QLC9gVSiRsYY7m%2F1gDBX4AqZYrAssDRaZGLSuJCS1vO3XdlAEi6wwvvBnw%2F7kpPXlvvTkihAzYTAaa23Bm4V8t75CPdQDzIQHaN3M0FRNaAXjNjC0CMOSUS5phxHUFahCK1gjhjlIcgIlwRqUkS2zJbpy7wjI571sVTQ5wN4is7A17GAV18P3ED08EzW%2B5PRztCbkvo%2B84NgOH3IgYZG7tuF3MBr30A8myvuxwgLP%2Be5cUjRpmU%2F46XN8vsdnqZ7t1hSklTho2pkGpaIePQT%2FdRqTT9BwAA%22%7D"
export var fontFamily = 'rfy2xz0';
export var styledFont = 'rfy2xz3 rfy2xz1 rfy2xz0';
export var styledFont12 = 'rfy2xza rfy2xz1 rfy2xz0';
export var styledFont12Bold = 'rfy2xzb rfy2xz2 rfy2xz0';
export var styledFont12BoldNowrapWhite = 'rfy2xzf rfy2xz2 rfy2xz0';
export var styledFont12Danger = 'rfy2xzd rfy2xz1 rfy2xz0';
export var styledFont12DangerCenter = 'rfy2xze rfy2xzd rfy2xz1 rfy2xz0';
export var styledFont12Deep = 'rfy2xzc rfy2xz1 rfy2xz0';
export var styledFont14 = 'rfy2xzg rfy2xz1 rfy2xz0';
export var styledFont14Bold = 'rfy2xzh rfy2xz2 rfy2xz0';
export var styledFont16 = 'rfy2xzi rfy2xz1 rfy2xz0';
export var styledFont16Bold = 'rfy2xzj rfy2xz2 rfy2xz0';
export var styledFont16Error = 'rfy2xzn rfy2xz1 rfy2xz0';
export var styledFont16Main = 'rfy2xzm rfy2xz1 rfy2xz0';
export var styledFont16Nowrap = 'rfy2xzk rfy2xz1 rfy2xz0';
export var styledFont16White = 'rfy2xzl rfy2xz1 rfy2xz0';
export var styledFont18 = 'rfy2xzo rfy2xz1 rfy2xz0';
export var styledFont18Bold = 'rfy2xzp rfy2xz2 rfy2xz0';
export var styledFont20BoldDanger = 'rfy2xzq rfy2xz2 rfy2xz0';
export var styledFont24Dark = 'rfy2xzr rfy2xz1 rfy2xz0';
export var styledFontBold = 'rfy2xz5 rfy2xz2 rfy2xz0';
export var styledFontBoldBlack06 = 'rfy2xz6 rfy2xz2 rfy2xz0';
export var styledFontBoldNowrap = 'rfy2xzv';
export var styledFontBoldNowrapSx = 'rfy2xzw';
export var styledFontBoldSx = 'rfy2xzt';
export var styledFontDeep = 'rfy2xz7 rfy2xz1 rfy2xz0';
export var styledFontDeepNowrap = 'rfy2xz8 rfy2xz1 rfy2xz0';
export var styledFontError = 'rfy2xz9 rfy2xz1 rfy2xz0';
export var styledFontNowrap = 'rfy2xz4 rfy2xz1 rfy2xz0';
export var styledFontNowrapSx = 'rfy2xzu';
export var styledFontSx = 'rfy2xzs';